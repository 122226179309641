export function formatNumberWithMetricPrefix(number: number): string {
    const units = ['', 'K', 'M', 'B', 'T']
    let unitIndex = 0
    let num = number

    while (num >= 1000 && unitIndex < units.length - 1) {
        num /= 1000
        unitIndex++
    }

    return number === 0 ? '0' : `${(num ?? 0).toFixed(2)}${units[unitIndex]}`
}
