export type SignalMetadata = {
    name: string
    namespace: string
    min: number
    max: number
    unit: string
    description: string
}

export type TwoDimensionalQueryResult = {
    name: string
    annotations: Map<string, number>
    values: Array<Array<number>>
}

export type SignalList = {
    metadata: Array<SignalMetadata>
    signals: Array<SignalListEntry>
}

export type SignalNameList = {
    signals: Array<SignalNameEntry>
}

export type SignalIdentifier = {
    namespace: string | undefined
    frameName: string
    signalName: string
}

export type SignalEntry = {
    frameName: string
    name: string
    namespace: string
    start: number
    length: number
    byteOrder: string
    isSigned: boolean
    min: number
    max: number
    unit: string
    comments: string
}

export type VisualizeProcessingTracker = {
    name: string
    step: VisualizeProcessingTrackerStep
    status: VisualizeProcessingTrackerStatus
    errors: Array<ProcessingError>
}

export enum VisualizeProcessingTrackerStep {
    DECODE_DBC = 'DECODE_DBC',
    STORE_DBC = 'STORE_DBC',
    DECODE_RECORDINGS = 'DECODE_RECORDINGS',
    STORE_RECORDINGS = 'STORE_RECORDINGS',
}

export enum VisualizeProcessingTrackerStatus {
    QUEUED = 'QUEUED',
    RUNNING = 'RUNNING',
    FINISHED = 'FINISHED',
    FAILED = 'FAILED',
}

export type FrameEntry = {
    name: string
    namespace: string
    cnt: number
    id: number
    signalDb: string
    cycleTime: number
    comments: string
    senders: Array<string>
    receivers: Array<string>
    signals: Array<SignalEntry>
}

export type SignalNameEntry = {
    name: string
    occurrences: number
}

export type SignalListEntry = {
    name: string
    signals: Array<Array<number>>
}

export type UserLicense = {
    creator: string
    licenses: Array<License>
}

export type License = {
    created: string
    expires: string
    creator: string
    hash: string
    renewCount: number
}

export type BrokerContainer = {
    name: string // TODO - Remove this or rename shortName to name
    shortName: string
    url: string | undefined
    labels: Labels
    creator: ContainerCreator
    createTime: string
    status: string
    keys: Array<string>
    tag: string | undefined
}

export type ContainerCreator = {
    uid: string
    email: string
    displayName: string
}

export type Labels = {
    creator: string
    bu: string
    project: string
}

export type ProjectServiceAccountDTO = {
    creator: string
    created: string
    name: string
    roles: Array<string>
}

export type ProjectServiceAccount = {
    creator: string
    created: string
    name: string
    roles: Array<string>
}

export type ServiceAccountKeyCreatedDTO = {
    created: string
    expires: string
    name: string
    project: string
    token: string
    serviceAccount: string
}

export type CreateServiceAccountTokenDTO = {
    daysUntilExpiry: number
}

export type CreateProjectServiceAccountDTO = {
    name: string
    roles: Array<string>
}

export type EditProjectServiceAccountDTO = {
    roles: Array<string>
}

// TODO split to smaller files, TOKEN RELATED STUFF BELOW

export type AccessToken = {
    creator: string
    created: string
    name: string
    expires: string
    revoked: boolean
}

export type PersonalAccessTokenDTO = {
    expires: string
    revoked: boolean
    name: string
    created: string
}

export type ServiceAccountTokenDTO = {
    expires: string
    revoked: boolean
    creator: string
    name: string
    created: string
}

export type UserInfoWithRoles = {
    user: UserInfo
    roles: Array<string>
}

export type UserInfo = {
    uid: string
    displayName: string
    email: string
}

export type CreateUserInfoWithRoles = {
    user: NewUserInfo
    roles: Array<string>
}

export type CreateProjectRequest = {
    uid: string
    displayName: string
    description: string
}

export type CreateBuRequest = {
    displayName: string
}

export type AgreementsAnswersRequest = {
    eulaAccepted: boolean
    privacyPolicyAccepted: boolean
}

export enum FeatureKey {
    VISUALIZE_RECORDING = 'VISUALIZE_RECORDING',
}

export type FeatureEnabledStateRequest = {
    isEnabled: boolean
    featureKey: FeatureKey
}

export type NewUserInfo = {
    displayName: string
    email: string
    uid: string | undefined
}

export type UserBillableUnitInfo = {
    organisation: Organisation
    projects: Array<Project>
}

export type BillableUnit = {
    displayName: string
    description: string
    uid: string
}

export type ContainerSpec = {
    size: string
}

export type Folder = {
    type: string
    files: Array<File>
}

export type RecordingSession = {
    displayName: string
    description: string | undefined
    sessionId: string
    uploaded: string
    recordings: Array<RecordingFile>
    mediaFiles: Array<MediaFile>
    sourceRecordingFiles: Array<SourceRecordingFile>
    brokerConfigurations: Array<BrokerConfigurationFolder>
}

export type RecordingMetaData = {
    database: string | undefined
    namespace: string | undefined
    error: boolean
    message: string | undefined
    type: string | undefined
}

export type RecordingFile = {
    fileName: string
    downloadUrl: string
    displayName: string
    metadata: RecordingMetaData | undefined
    type: string | undefined
}

export type ProcessingRecordingFile = {
    fileName: string
    uploadId: string
    parentRecordingSessionId: string | undefined
    errors: Array<ProcessingError>
    step: ProcessingRecordingFileStep
    status: ProcessingRecordingFileStatus
}

export enum ProcessingRecordingFileStep {
    REQUESTED = 'REQUESTED',
    VALIDATING = 'VALIDATING',
    CONVERT = 'CONVERT',
    SPLIT = 'SPLIT',
    FINALIZE = 'FINALIZE',
    ZIP = 'ZIP',
    DONE = 'DONE',
}

export enum ProcessingRecordingFileStatus {
    READY = 'READY',
    RUNNING = 'RUNNING',
    DONE = 'SUCCESS',
    FAILED = 'FAILED',
}

export type ProcessingError = {
    message: string
    code: number
}

export type MediaFile = {
    fileName: string
    downloadUrl: string
    displayName: string
    mimeType: string
    sizeInBytes: number
    preferDownload: boolean
    startTimeZoned: string
}

export type SourceRecordingFile = {
    fileName: string
    downloadUrl: string
    displayName: string
    mimeType: string
    sizeInBytes: number
}

export type BrokerConfigurationFolder = {
    name: string
    files: Array<BrokerConfigurationFile>
}

export type BrokerConfigurationFile = {
    name: string
}

export type TokenTtl = {
    ttl: number
}

export type AuthenticatedUser = {
    email: string
    firstName: string
    lastName: string
    picture: string | undefined
    uid: string
}

export type Organisation = {
    description: string
    displayName: string
    uid: string
    permissions: Array<string>
}

export interface Project {
    uid: string
    displayName: string
    description: string
    roles: Array<string>
    permissions: Array<string>
}

export interface SignalDatabase {
    name: string
    uploaded: string
    type: string
    size: string
}

export const Status = {
    IDLE: 'IDLE',
    RUNNING: 'RUNNING',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
}

export interface GenericFileDownloadUrlResponse {
    downloadUrl: string
}

export interface FileObjectsContainer {
    objects: Array<FileObject>
    path: string
}

export interface FileObject {
    name: string
    type: FileObjectTypeEnum
    size?: number
    path: string
}

export enum FileObjectTypeEnum {
    DIRECTORY = 'DIRECTORY',
    FILE = 'FILE',
}
