import { AuthenticatedUser, Organisation, UserBillableUnitInfo } from '../api/CloudApi/types'
import { AnalyticsBrowser } from '@segment/analytics-next'
import { isDemo } from './demo'
import { useEffect, useRef } from 'react'

export interface ProductAnalyticsClient {
    track(event: string, properties?: Object, options?: SegmentAnalytics.SegmentOpts, callback?: () => void): void
    page(event: string, properties?: Object, options?: SegmentAnalytics.SegmentOpts, callback?: () => void): void
    identify(
        user: AuthenticatedUser,
        properties?: Object,
        options?: SegmentAnalytics.SegmentOpts,
        callback?: () => void
    ): void
}

export type AnalyticsProperties = {
    analyticsContext: AnalyticsContext
    currentUser: AuthenticatedUser | undefined
    currentBillableUnit: UserBillableUnitInfo | undefined
}

export enum AnalyticsContext {
    NOT_SET = 'NOT_SET',
    ORGANISATION_HOME = 'ORGANISATION_HOME',
    VISUALIZE_TAB = 'RECORDING_DETAILS_VISUALIZE',
    PLAYBACK_TAB = 'RECORDING_DETAILS_VISUALIZE_PLAYBACK',
    FILES_TAB = 'RECORDING_DETAILS_FILES',
    RECORDINGS_LIST = 'RECORDINGS_LIST',
    MANAGE_PROJECT = 'MANAGE_PROJECT',
}

export const createAnalyticsTrackingKey = (analyticsContext: AnalyticsContext, analyticsAction: string) => {
    return `${analyticsContext}-${analyticsAction}`
}

export function useProductAnalyticsClient(props: ProductAnalyticsProps): ProductAnalyticsClient {
    const analytics = useRef(new ProductAnalytics(props.billableUnit?.organisation))

    useEffect(() => {
        if (props.billableUnit) {
            analytics.current.organisation = props.billableUnit.organisation
        }
    }, [props.user, props.billableUnit])

    return analytics.current
}

const analytics = AnalyticsBrowser.load(
    {
        writeKey: 'Hbf4q21GqpXq74emHsbkz4j4jFW5zrtP',
        cdnURL: 'https://pa-cdn.remotivelabs.com',
    },
    {
        integrations: {
            'Segment.io': {
                apiHost: 'pa.remotivelabs.com/v1',
                protocol: 'https',
            },
        },
    }
)

export interface ProductAnalyticsProps {
    user: AuthenticatedUser | undefined
    billableUnit: UserBillableUnitInfo | undefined
}

export class ProductAnalytics {
    organisation?: Organisation

    constructor(organisation?: Organisation) {
        this.organisation = organisation
    }

    /**
     * Convenience wrapper around Segment Analytics.js
     *
     * For more information on how to extend it visit:
     * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/
     */

    getEnvironment() {
        if (isDemo()) {
            return 'demo'
        }
        if (window.location.host.endsWith('cloud-dev.remotivelabs.com')) {
            return 'dev'
        }
        if (window.location.host.endsWith('localhost:3000')) {
            return 'local'
        }
        if (window.location.host.endsWith('cloud.remotivelabs.com')) {
            return 'prod'
        }
        return undefined
    }

    getProps() {
        return {
            app: 'Remotive Cloud Console',
            environment: this.getEnvironment(),
            organisation_id: this.organisation?.uid,
        }
    }

    public identify(
        user: AuthenticatedUser,
        properties?: Object,
        options?: SegmentAnalytics.SegmentOpts,
        callback?: () => void
    ) {
        if (this.getEnvironment()) {
            try {
                analytics.identify(user.uid, properties, options, callback)
            } catch (e: any) {
                console.warn(`Failed to capture analytics identify event`)
            }
        }
    }

    public track(event: string, properties?: Object, options?: SegmentAnalytics.SegmentOpts, callback?: () => void) {
        const props = this.getProps()
        const merged = {
            ...props,
            ...(properties ? properties : {}),
        }
        if (this.getEnvironment() !== undefined) {
            try {
                analytics.track(event, merged, options, callback)
            } catch (e: any) {
                console.warn(`Failed to capture analytics track event`)
            }
        }

        return undefined
    }

    public page(name?: string, properties?: Object, options?: SegmentAnalytics.SegmentOpts, callback?: () => void) {
        const props = this.getProps()
        const merged = {
            ...props,
            ...(properties ? properties : {}),
        }
        if (this.getEnvironment() !== undefined) {
            try {
                analytics.page(name, merged, options, callback)
            } catch (e: any) {
                console.warn(`Failed to capture analytics page event`)
            }
        }

        return undefined
    }
}
