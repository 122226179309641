import { useEffect, useState } from 'react'
import { Accordion, Card, Container } from 'react-bootstrap'
import { AuthenticatedUser, Project, UserBillableUnitInfo } from '../../api/CloudApi/types'
import { isDemo, setGuidedDemo } from '../../utils/demo'
import {
    ArrowNorthWestIcon,
    ArrowSouthIcon,
    ArrowSouthWestIcon,
    ArrowWestIcon,
    BrokersIcon,
    CurrentBillableUnitIcon,
    ExternalLinkIcon,
    HelpIcon,
    HomeIcon,
    ProjectSettingsIcon,
    RecordingsIcon,
    WavingHandIcon,
} from '../../assets/Icons'
import { BreadCrumb, BreadCrumbs } from '../../types/BreadCrumbs'
import { Permission, hasPermission } from '../../utils/permission'
import { NavLink, useSearchParams } from 'react-router-dom'
import { OrganisationRoutes, ProjectRoutes } from '../../Routes'
import { GitHub } from '@mui/icons-material'
import RecordingListItem from '../../components/RecordingSessionListItem'
import NavigationBar from '../../components/navigation/NavigationBar'
import HelpOverlayModalModal from '../../components/modals/HelpOverlayModal'
import IconAndLinkCard from './IconAndLinkCard'
import { getRecentRecordings } from '../../types/RecentRecording'
import { getRecentBrokers } from '../../types/RecentBroker'
import BrokerListItem from '../../components/BrokerListItem'
import { IS_NEW_USER_PARAM } from '../../utils/queryParams'
import WelcomeModal from './WelcomeModal'
import { PageDetails } from '../../utils/pageDetails'
import { ConfirmDialogProperties } from '../../types/ConfirmDialogProperties'
import { AnalyticsContext, AnalyticsProperties } from '../../utils/ProductAnalytics'

interface OrganisationProps {
    updateCurrentPageDetails: (pageDetails: PageDetails) => void
    billableUnits: Array<UserBillableUnitInfo>
    currentUser: AuthenticatedUser | undefined
    currentBillableUnit: UserBillableUnitInfo | undefined
    currentProject: Project | undefined
    openConfirmationDialog: (confirmDialogProperties: ConfirmDialogProperties) => void
}

export default function Organisation(props: OrganisationProps) {
    const [searchParams] = useSearchParams()
    const isNewUser = searchParams.get(IS_NEW_USER_PARAM)

    const [showHelpModal, setShowHelpModal] = useState<boolean>(false)
    const [showDemoModal, setShowDemoModal] = useState<boolean>(false)
    const [showWelcomeModal, setShowWelcomeModal] = useState<boolean>(isNewUser === 'true' ?? false)

    useEffect(() => {
        console.debug('Mounted organisation page!')
        setGuidedDemo(false) // Default state of guided mode is off
    }, [])

    useEffect(() => {
        if (props.currentBillableUnit?.organisation.displayName) {
            props.updateCurrentPageDetails({
                documentTitle: props.currentBillableUnit?.organisation.displayName,
                productAnalyticsEventName: 'Organisation Home',
            })
        }
    }, [props.currentBillableUnit])

    const projectInfo = () => {
        return (
            <>
                <h5>
                    <b>Projects</b>
                </h5>
                <p className="remotive-font-md m-0">
                    Project is our abstraction of a workspace - a place to share, playback and collaborate around
                    recordings.
                </p>

                <ul className="remotive-font-md m-2">
                    <li>
                        <a target={'_blank'} href={'https://docs.remotivelabs.com/docs/cloud/Project/Brokers'}>
                            Run brokers in the cloud
                        </a>
                        , consume from anywhere
                    </li>
                    <li>
                        <a target={'_blank'} href={'https://docs.remotivelabs.com/docs/cloud/Project/Recordings'}>
                            Upload recordings
                        </a>
                    </li>
                    <li>
                        <a target={'_blank'} href={'https://docs.remotivelabs.com/docs/cloud/Project/SignalDatabases'}>
                            Upload signal database files
                        </a>
                        - candb, fibex
                    </li>
                    <li>
                        <a
                            target={'_blank'}
                            href={'https://docs.remotivelabs.com/docs/cloud/Project/Recordings#manage-media-files'}
                        >
                            Video and images
                        </a>
                    </li>
                    <li>
                        <a
                            target={'_blank'}
                            href={
                                ' https://docs.remotivelabs.com/docs/cloud/Project/Recordings#play-a-recording-session'
                            }
                        >
                            Playback an uploaded recording
                        </a>
                    </li>
                </ul>
            </>
        )
    }

    const pageHeader = () => {
        return (
            <>
                <div className="text-start d-flex align-items-center">
                    <HomeIcon className="remotive-primary-50-color fs-3 me-1" />
                    <p className="fs-3 mb-0 lexend-light">{props.currentBillableUnit?.organisation.displayName}</p>
                </div>
            </>
        )
    }

    const breadCrumbs = () => {
        return {
            trail: [],
            current: {
                title: 'Home',
                route: undefined,
            } as BreadCrumb,
        } as BreadCrumbs
    }

    const helpTextSelectProject = () => {
        return (
            <>
                <div
                    className={`lexend-bold remotive-font-md text-light position-absolute d-flex flex-column d-${
                        showHelpModal ? 'block' : 'none'
                    }`}
                    style={{ zIndex: '9999', marginTop: 90, marginLeft: 0 }}
                >
                    <div className="d-flex align-items-center">
                        <ArrowNorthWestIcon sx={{ fontSize: 30 }} className="me-3 mb-4" />
                        <p className="text-light text-start m-0">Use this dropdown to switch project</p>
                    </div>
                </div>
            </>
        )
    }

    const helpTextCreateBroker = () => {
        return (
            <>
                <div
                    className={`lexend-bold remotive-font-md text-light position-absolute d-flex flex-column d-${
                        showHelpModal ? 'block' : 'none'
                    }`}
                    style={{ zIndex: '9999', marginTop: 170, marginLeft: 0 }}
                >
                    <div className="d-flex align-items-center">
                        <ArrowWestIcon sx={{ fontSize: 30 }} className="me-3" />
                        <p className="text-light text-start m-0">
                            Here is where you navigate things related <br /> to your current project
                        </p>
                    </div>
                </div>
            </>
        )
    }

    const demoTextRecording = () => {
        return (
            <div
                className={`lexend-bold remotive-font-md text-light position-absolute d-flex flex-column d-${
                    showDemoModal ? 'block' : 'none'
                }`}
                style={{ zIndex: '9999', marginLeft: -10, marginTop: 100 }}
            >
                <div className="d-flex align-items-center">
                    <ArrowWestIcon sx={{ fontSize: 30 }} className="me-3" />
                    <p className="text-light text-start m-0 fs-6">1. Go here to choose a recording to play</p>
                </div>
            </div>
        )
    }

    const helpTextOrganisation = () => {
        return (
            <div
                className={`lexend-bold remotive-font-md text-light position-absolute d-flex flex-column d-${
                    showHelpModal ? 'block' : 'none'
                }`}
                style={{ zIndex: '9999', marginTop: 450, marginLeft: 0 }}
            >
                <div className="d-flex flex-row">
                    <ArrowSouthWestIcon sx={{ fontSize: 30 }} className="me-2 mt-4" />
                    <p className="text-light text-start m-0">
                        Down here is where you navigate things <br />
                        related to your current organisation
                    </p>
                </div>
            </div>
        )
    }

    const teamCard = (className: string) => {
        return (
            <IconAndLinkCard
                icon={
                    <>
                        <CurrentBillableUnitIcon
                            sx={{ fontSize: 40 }}
                            className="d-block d-lg-none d-xl-block me-4 remotive-primary-40-color"
                        />
                        <CurrentBillableUnitIcon
                            sx={{ fontSize: 30 }}
                            className="d-none d-lg-block d-xl-none me-4 remotive-primary-40-color"
                        />
                    </>
                }
                buttonDisabledCondition={() =>
                    !hasPermission(Permission.ORG_VIEWER_USER, props.currentBillableUnit, props.currentProject)
                }
                internalRoute={OrganisationRoutes.toUsers(props.currentBillableUnit?.organisation.uid)}
                buttonText="Manage users"
                bodyText="Invite more members to your organisation and collaborate together, wherever you are."
                className={className}
            />
        )
    }

    const projectsCard = () => {
        return (
            <IconAndLinkCard
                icon={
                    <>
                        <ProjectSettingsIcon
                            sx={{ fontSize: 40 }}
                            className="d-block d-lg-none d-xl-block me-4 remotive-primary-40-color"
                        />
                        <ProjectSettingsIcon
                            sx={{ fontSize: 30 }}
                            className="d-none d-lg-block d-xl-none me-4 remotive-primary-40-color"
                        />
                    </>
                }
                buttonDisabledCondition={() =>
                    !hasPermission(Permission.PROJECT_VIEWER_ADMIN, props.currentBillableUnit, props.currentProject)
                }
                internalRoute={ProjectRoutes.toSettings(props.currentProject?.uid)}
                buttonText="Manage project"
                bodyText="Invite members of your organisation to your projects to work together, share recordings and use brokers
                collaboratively."
            />
        )
    }

    const githubCard = (className: string) => {
        return (
            <IconAndLinkCard
                icon={
                    <>
                        <GitHub
                            sx={{ fontSize: 40 }}
                            className="d-block d-lg-none d-xl-block me-4 remotive-primary-40-color"
                        />
                        <GitHub
                            sx={{ fontSize: 30 }}
                            className="d-none d-lg-block d-xl-none me-4 remotive-primary-40-color"
                        />
                    </>
                }
                buttonDisabledCondition={() => false}
                externalLink="https://github.com/remotivelabs"
                buttonText="Go to Github"
                bodyText="We have some samples and resources available on our Github page, check it out!"
            />
        )
    }

    const getStartedCard = () => {
        return (
            <Card className="border-0 remotive-gradient-horizontal rounded-4 shadow-sm px-1">
                <Card.Body className="pb-3 pt-3 text-start">
                    <div className="d-flex align-items-center">
                        <p className="mt-1 mb-0 fs-4 text-light">
                            <b>Get started with RemotiveCloud </b>
                        </p>
                        <div>
                            <button
                                className="btn remotive-btn-md remotive-btn-transparent  border-0 px-1 ms-1"
                                onClick={() => setShowWelcomeModal(true)}
                            >
                                <div className="d-flex align-items-center">
                                    <HelpIcon className="text-white" sx={{ fontSize: 20 }} />
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className="mt-3">
                        <button className="btn remotive-btn-md remotive-btn-success me-2">
                            <NavLink
                                to={'https://docs.remotivelabs.com/'}
                                target="blank"
                                className={'text-light text-decoration-none'}
                            >
                                <div className="d-flex align-items-center">
                                    <p className="m-0 me-2">Documentation</p>
                                    <ExternalLinkIcon sx={{ fontSize: 13 }} />
                                </div>
                            </NavLink>
                        </button>
                        <button className="btn remotive-btn-md remotive-btn-primary me-2">
                            <NavLink
                                to={'https://remotivelabs.com/contact/'}
                                target="blank"
                                className={'text-light text-decoration-none'}
                            >
                                <div className="d-flex align-items-center">
                                    <p className="m-0 me-2">Contact us</p>
                                    <ExternalLinkIcon sx={{ fontSize: 13 }} />
                                </div>
                            </NavLink>
                        </button>
                    </div>

                    <div className="mt-3">
                        <div className="d-flex align-items-center text-light">
                            <p className="m-0 ms-2 remotive-font-sm">
                                Don't forget that you can configure, control & run things using either the cloud console
                                or the command line using our{' '}
                                <a
                                    className="remotive-primary-40-color"
                                    target={'_blank'}
                                    href={'https://docs.remotivelabs.com/docs/category/remotivecli/'}
                                >
                                    CLI
                                </a>
                                .
                            </p>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        )
    }

    const activateGuidedMode = () => {
        setGuidedDemo(true)
        setShowDemoModal(true)
    }

    const getStartedCardForDemo = () => {
        return (
            <Card className="border-0 rounded-4 shadow-sm px-1 mb-4" style={{ minHeight: '200px' }}>
                <Card.Body className="pb-3 pt-3 text-start">
                    <div className="p-2 pt-3 row d-flex align-items-center">
                        <div className="col-12 col-lg-6">
                            <div className="d-flex align-items-center m-2 mx-3">
                                <WavingHandIcon sx={{ fontSize: 40 }} className="me-4 remotive-primary-60-color" />
                                <p className="fs-3 m-0">Welcome to our demo!</p>
                            </div>
                            <p className="mt-5">
                                We are humbled to have you here - experiment with signals from our demo drive cycle
                                right away:
                            </p>
                            <ul>
                                <li>View and replay signals (web client / reference library)</li>
                                <li>Work with and transform signals to Android properties / VSS</li>
                                <li>Apply your own code</li>
                            </ul>
                            <div className="d-flex flex-wrap justify-content-center align-items-top">
                                <div className="d-flex flex-column align-items-center">
                                    <div className="d-flex flex-row">
                                        <NavLink
                                            to={ProjectRoutes.toRecordingsList(props.currentProject?.uid)}
                                            className={'text-light text-decoration-none'}
                                        >
                                            <button className="btn remotive-btn remotive-font-md remotive-btn-primary m-1">
                                                Start demo
                                            </button>
                                        </NavLink>
                                        <div className="d-flex text-center">
                                            <button
                                                className="d-none d-lg-inline-block btn remotive-font-md remotive-btn remotive-btn-success m-1"
                                                onClick={() => activateGuidedMode()}
                                            >
                                                Guide me
                                            </button>
                                            <div className="d-inline-block d-lg-none d-flex flex-column">
                                                <button
                                                    disabled
                                                    className=" btn remotive-btn remotive-font-md remotive-btn-success m-1"
                                                    onClick={() => activateGuidedMode()}
                                                >
                                                    Guide me
                                                </button>
                                                <span className="remotive-font-xxs">
                                                    Only available on large screens
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-3 remotive-font-xs">
                                <p>
                                    By the way, we are really happy for you to try out or stuff beyond this demo! Learn
                                    how to get your{' '}
                                    <a target={'_blank'} href={'https://docs.remotivelabs.com/docs/remotive-broker/'}>
                                        free 30 days hardware evaluation licence
                                    </a>{' '}
                                    and don’t forget to explore our{' '}
                                    <a target={'_blank'} href={'https://github.com/remotivelabs'}>
                                        GitHub
                                    </a>{' '}
                                    for available integrations and Q&As.
                                </p>
                                <p>Let’s get stuff done together! /RemotiveLabs dev team</p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="mt-lg-3 mt-5 text-center">
                                <img
                                    width="auto"
                                    height="auto"
                                    style={{ maxWidth: '100%', maxHeight: '400px' }}
                                    src={'https://releases.beamylabs.com/cloud-console-assets/remotive_cloud_info.png'}
                                />
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        )
    }

    const recentBrokersCard = () => {
        const recentBrokers = getRecentBrokers(props.currentBillableUnit)
        if (recentBrokers !== undefined && recentBrokers.length > 0) {
            return (
                <Card className="shadow-sm rounded-4 border-0 text-start">
                    <Card.Body className="p-0 d-flex flex-column">
                        <div className="mx-0 d-flex align-items-center flex-fill flex-truncate">
                            <Accordion defaultActiveKey={'0'} flush className="mx-2 p-1 w-100 flex-truncate">
                                <Accordion.Item className="mx-0 px-0 flex-truncate" eventKey="0">
                                    <Accordion.Header className="mx-0 p-0 flex-truncate">
                                        <div className="m-1 mb-0 flex-truncate">
                                            <div className="d-flex align-items-center">
                                                <BrokersIcon
                                                    sx={{ fontSize: 20 }}
                                                    className="me-2 remotive-primary-50-color"
                                                />
                                                <p className="m-0 remotive-font">Recently used brokers</p>
                                            </div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body className="mx-0 pt-0 pb-1 px-0">
                                        {recentBrokers.map((it) => (
                                            <div
                                                key={`${it.billableUnit.organisation.uid}${it.project.uid}${it.broker.name}`}
                                                className={`position-relative rounded remotive-primary-10-background m-1 mx-0`}
                                            >
                                                <div className="px-1">
                                                    <BrokerListItem
                                                        currentProject={it.project}
                                                        currentBillableUnit={it.billableUnit}
                                                        broker={it.broker}
                                                        useProjectNameInsteadOfUrl={true}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Card.Body>
                </Card>
            )
        }
        return <></>
    }

    const recentRecordingsCard = () => {
        const recentRecordings = getRecentRecordings(props.currentBillableUnit)
        if (recentRecordings !== undefined && recentRecordings.length > 0) {
            return (
                <Card className="shadow-sm rounded-4 border-0 text-start">
                    <Card.Body className="p-0 d-flex flex-column">
                        <div className="mx-0 d-flex align-items-center flex-fill flex-truncate">
                            <Accordion defaultActiveKey={'0'} flush className="mx-2 p-1 w-100 flex-truncate">
                                <Accordion.Item className="mx-0 px-0 flex-truncate" eventKey="0">
                                    <Accordion.Header className="mx-0 p-0 flex-truncate">
                                        <div className="m-1 mb-0 flex-truncate">
                                            <div className="d-flex align-items-center">
                                                <RecordingsIcon
                                                    sx={{ fontSize: 20 }}
                                                    className="me-2 remotive-primary-50-color"
                                                />
                                                <p className="m-0 remotive-font">Recently used recording sessions</p>
                                            </div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body className="mx-0 pt-0 pb-1 px-0">
                                        {recentRecordings.map((it) => (
                                            <div
                                                key={`${it.billableUnit.organisation.uid}${it.project.uid}${it.recordingSession.sessionId}`}
                                                className={`position-relative rounded remotive-primary-10-background m-1 mx-0`}
                                            >
                                                <div className="px-1">
                                                    <RecordingListItem
                                                        isRecentlyUploaded={false}
                                                        recording={it.recordingSession}
                                                        project={it.project}
                                                        billableUnit={it.billableUnit}
                                                        analyticsProperties={
                                                            {
                                                                currentBillableUnit: props.currentBillableUnit,
                                                                currentUser: props.currentUser,
                                                                analyticsContext: AnalyticsContext.ORGANISATION_HOME,
                                                            } as AnalyticsProperties
                                                        }
                                                        isLocked={isDemo()}
                                                        useProjectNameInsteadOfFilesLength={true}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Card.Body>
                </Card>
            )
        }
        return <></>
    }

    return (
        <div className="d-flex">
            <NavigationBar
                billableUnits={props.billableUnits}
                currentUser={props.currentUser}
                currentBillableUnit={props.currentBillableUnit}
                projects={props.currentBillableUnit?.projects || []}
                currentProject={props.currentProject}
                breadCrumbs={breadCrumbs()}
                setHelpOverlayFunction={setShowHelpModal}
                setDemoOverlayFunction={setShowDemoModal}
                zIndexOnTop={showHelpModal || showDemoModal}
                isDemoGuideActive={showDemoModal}
                openConfirmationDialog={props.openConfirmationDialog}
            />
            <Container fluid className="mt-5 pb-5 d-flex flex-column">
                {helpTextCreateBroker()}
                {demoTextRecording()}
                {helpTextSelectProject()}
                {helpTextOrganisation()}
                <div className="mt-3">{pageHeader()}</div>
                {isDemo() ? (
                    <>
                        <div className="mt-2">{getStartedCard()}</div>
                        <div>{getStartedCardForDemo()}</div>
                    </>
                ) : (
                    <>
                        <div className="mt-2 mx-1">{getStartedCard()}</div>
                        <div className="mt-3 d-flex flex-row">
                            {teamCard('ms-1')}
                            {projectsCard()}
                            {githubCard('me-1')}
                        </div>
                        <div className="mt-3 mx-1">{recentRecordingsCard()}</div>
                        <div className="mt-3 mx-1">{recentBrokersCard()}</div>
                    </>
                )}
            </Container>

            {/* Modals below */}
            <WelcomeModal handleCloseFunction={() => setShowWelcomeModal(false)} show={showWelcomeModal} />
            <HelpOverlayModalModal
                handleCloseFunction={() => {
                    setShowHelpModal(false)
                    setShowDemoModal(false)
                }}
                show={showHelpModal || showDemoModal}
            />
        </div>
    )
}
