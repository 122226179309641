import Highcharts, { Chart, HTMLDOMElement } from 'highcharts/highstock'
import Exporting from 'highcharts/modules/exporting'
import Accessibility from 'highcharts/modules/accessibility'
import CloudApi from '../../../api/CloudApi'
import { useEffect, useRef, useState } from 'react'
import { FrameEntry, Project, SignalEntry } from '../../../api/CloudApi/types'
import { toast } from 'react-toastify'
import LoadingContainer from '../../../components/LoadingContainer'
import ErrorContainer from '../../../components/ErrorContainer'
import { components, OnChangeValue, default as ReactSelect } from 'react-select'
import { CloseRounded, WarningRounded } from '@mui/icons-material'
import { SignalOption } from '../../../types/SignalOption'
import {
    ANALYTICS_CHART_TYPE_KEY,
    AnalyticsPanelType,
    TimeDistributionResult,
    PanelKey,
    TimeDistributionPanel,
} from './Types'

interface TimeDistributionContainerProps {
    updatePanel: (panel: TimeDistributionPanel) => void
    removeThisPanelFunction: () => void
    currentProject: Project | undefined
    recordingSessionId: string | undefined
    panelKey: PanelKey
    selectedFrame?: FrameEntry
    availableFramesWithCycleTime: Array<FrameEntry>
}

export default function TimeDistributionContainer(props: TimeDistributionContainerProps) {
    Exporting(Highcharts)
    Accessibility(Highcharts)

    const [selectedFrame, setSelectedFrame] = useState<FrameEntry | undefined>(props.selectedFrame)
    const [pageState, setPageState] = useState(PageState.DONE)
    const [queryResultValues, setQueryResultValues] = useState<TimeDistributionResult>()
    const [chart, setChart] = useState<Chart | undefined>(undefined)
    const [errorText, setErrorText] = useState<string>()

    useEffect(() => {
        console.log('Mounting time distribution container!')
    }, [])

    useEffect(() => {
        console.log(`Time distribution chart is ${chart}`)
        return () => {
            console.log('Destoying time distribution chart!')
            chart?.destroy()
        }
    }, [chart])

    useEffect(() => {
        if (selectedFrame !== undefined) {
            console.log(props.panelKey)
            const panelToStore = { panelKey: props.panelKey, selectedFrame } as TimeDistributionPanel
            props.updatePanel(panelToStore)
            getSignalDataToPlot(selectedFrame)
        }
    }, [selectedFrame])

    useEffect(() => {
        if (queryResultValues && queryResultValues.values.length > 0) {
            renderGraph()
        } else {
        }
    }, [queryResultValues])

    /**
     * Override the reset function, we don't need to hide the tooltips and
     * crosshairs.
     */
    Highcharts.Pointer.prototype.reset = function () {
        return undefined
    }

    const renderGraph = () => {
        // TODO - Moving forward we should be able to handle 1 or more results with the same code,
        //       we need somehow reuse this, thats why its still a list and works as in the timeseries chart,
        const queryResultValuesList = [queryResultValues!]
        //const a = [1, 2]
        //.forEach((n) => console.log(n))

        var chartDiv: HTMLDOMElement = document.createElement('div')
        chartDiv.className = 'chart'
        chartDiv.setAttribute(ANALYTICS_CHART_TYPE_KEY, AnalyticsPanelType.TIME_DISTRIBUTION)
        document.getElementById(props.panelKey.key)!.appendChild(chartDiv)

        const series = queryResultValuesList.map((entry, i) => {
            // Gets the min and max cycle times found
            const min = entry.values[0][0]
            const max = entry.values[entry.values.length - 1][0]

            // Make sure that if cycle time is outside the min/max add 0 count to series
            // so we can render the annotation even if outside
            if (entry.annotations.expectedCycleTime > max) {
                entry.values.push([entry.annotations.expectedCycleTime, 0])
            } else if (entry.annotations.expectedCycleTime < min) {
                entry.values.unshift([entry.annotations.expectedCycleTime, 0])
            }

            return {
                name: entry.name,
                data: entry.values,
                selected: true,
                yAxis: i,
                color: Highcharts.getOptions().colors![i],
                tooltip: {
                    headerFormat: '{point.key} ms<br>',
                    valueSuffix: ' occurrence(s)',
                    valueDecimals: 0,
                },
            }
        })

        const yAxis = queryResultValuesList.map((entry, i) => {
            const a = {
                //showLastLabel: true,
                crosshair: true,
                lineWidth: 1,
                opposite: i % 2 === 0,
                title: {
                    text: entry.name,
                    style: {
                        color: Highcharts.getOptions().colors![i],
                    },
                },
            }
            return a
        })

        const options: any = {
            credits: {
                enabled: false
            },
            chart: {
                backgroundColor: '#fff',
                type: 'column',
                panning: true,
                panKey: 'shift',
                zoomType: 'x',
                height: 465,
            },
            title: 'Unused',
            responsive: {
                rules: [
                    {
                        condition: {
                            maxWidth: 400,
                            maxHeight: 200,
                        },
                        chartOptions: {
                            legend: {
                                align: 'center',
                                verticalAlign: 'bottom',
                                layout: 'horizontal',
                            },
                        },
                    },
                ],
            },

            navigator: { enabled: false },
            rangeSelector: {
                buttons: [
                    {
                        type: 'second',
                        count: 5,
                        text: '5s',
                    },
                    {
                        type: 'second',
                        count: 10,
                        text: '10s',
                    },
                    {
                        type: 'second',
                        count: 30,
                        text: '30s',
                    },
                    {
                        type: 'minute',
                        count: 1,
                        text: '1min',
                    },
                    {
                        type: 'minute',
                        count: 5,
                        text: '5m',
                    },
                    {
                        type: 'all',
                        text: 'All',
                    },
                ],
                inputEnabled: false, // it supports only days
                selected: 5,
            },

            legend: {
                enabled: false,
            },
            series: series,
            colors: [
                '#9b20d9',
                '#9215ac',
                '#861ec9',
                '#7a17e6',
                '#7010f9',
                '#691af3',
                '#6225ed',
                '#5b30e7',
                '#533be1',
                '#4c46db',
                '#4551d5',
                '#3e5ccf',
                '#3667c9',
                '#2f72c3',
                '#277dbd',
                '#1f88b7',
                '#1693b1',
                '#0a9eaa',
                '#03c69b',
                '#00f194',
            ],
            plotOptions: {
                flags: {
                    accessibility: {
                        exposeAsGroupOnly: true,
                        description: 'Flagged events.',
                    },
                },
                line: {
                    marker: {
                        enabled: true,
                    },
                },
            },
            yAxis: yAxis,

            //plotBands: plotMinMaxBands(entry),
            xAxis: {
                labels: {
                    format: '{text} ms',
                },
                type: 'linear',
                crosshair: true,
                plotLines: [
                    {
                        color: 'green', // Color value
                        dashStyle: 'shortdash', // Style of the plot line. Default to solid
                        // Unable to use Map.get() here so this is instead of that
                        value: queryResultValuesList[0].annotations.expectedCycleTime, // Value of where the line will appear
                        width: 1, // Width of the line
                        label: {
                            text: 'Expected cycle time',
                        },
                        zIndex: 10,
                    },
                ],
            },
        }

        Highcharts.chart(chartDiv, options, (loadedChart) => setChart(loadedChart))
        /*
        const b = ['mousemove', 'touchmove', 'touchstart']
        b.forEach(function (eventType: any) {
            document.getElementById(props.panelKey)!.addEventListener(eventType, function (e) {
                var chart, point, i, event

                for (i = 0; i < Highcharts.charts.length; i = i + 1) {
                    chart = Highcharts.charts[i]
                    // Find coordinates within the chart
                    event = chart!.pointer.normalize(e)
                    // Get the hovered point
                    //chart!.series.forEach((s) => {})

                    //console.log(chart!.series)

                    point = chart!.series[0].searchPoint(event, true)
                    if (point) {
                        // @ts-ignore
                        point.highlight(e)
                    }
                }
            })
        })
        */
    }

    const onError = (err: any) => {
        console.error(err)
        setPageState(PageState.ERROR)
        if (err.response.status === 404) {
            setErrorText('No data points found for the selected signal.')
        } else {
            setErrorText('We encountered a problem while querying signal data.')
        }
    }

    const getSignalDataToPlot = async (selectedFrame: FrameEntry) => {
        setPageState(PageState.LOADING)
        //setQueryResultValues(undefined)
        if (selectedFrame !== undefined) {
            const q = `select (${`${selectedFrame.namespace}:${selectedFrame.name}`})`
            //MonolithApi.getSignalsWithQuery(q)
            CloudApi.getJitter(
                props.currentProject!.uid,
                props.recordingSessionId!,
                `${selectedFrame.namespace}:${selectedFrame.name}`
            )
                .then((res) => {
                    setPageState(PageState.DONE)
                    setQueryResultValues(res.data as unknown as TimeDistributionResult)
                    if (res.data.values.length === 0) {
                        toast.warning('No signals found matching your query')
                    }
                    //setSelectedFrame(undefined)
                })
                .catch(onError)
        }
    }

    const Option = (props: any) => {
        return (
            <div>
                <components.Option {...props}>
                    <input type="checkbox" checked={props.isSelected} onChange={() => null} />{' '}
                    <label>{props.label}</label>
                </components.Option>
            </div>
        )
    }

    const handleChange = (newValue: any) => {
        const frame = props.availableFramesWithCycleTime.find((it) => `${it.namespace}:${it.name}` === newValue.label)
        setSelectedFrame(frame)
    }

    return (
        <>
            <div className="p-2">
                {props.availableFramesWithCycleTime && (
                    <>
                        <div className="d-flex justify-content-between">
                            <div>
                                <p className="remotive-font-md lh-sm remotive-primary-60-color m-0">
                                    Cycle time distribution
                                </p>
                                <p className="remotive-font-xs lh-1 remotive-primary-40-color text-secondary m-0">
                                    Visualizes actual cycle time compared to expected cycle time of a frame
                                </p>
                            </div>
                            <div>
                                <button
                                    onClick={() => props.removeThisPanelFunction()}
                                    className="btn m-0 p-0 remotive-btn-no-bg remotive-btn-sm"
                                >
                                    <div className="d-flex align-items-center" title="Close entire panel">
                                        <CloseRounded sx={{ fontSize: 24 }} />
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div className="mt-2">
                            <ReactSelect
                                noOptionsMessage={(obj: { inputValue: string }) => {
                                    return (
                                        <div className="remotive-font-md d-flex align-items-center justify-content-center">
                                            <WarningRounded className="text-warning me-2" sx={{ fontSize: 23 }} />
                                            <p className="m-0 text-truncate text-dark">
                                                No signals with cycle time available
                                            </p>
                                        </div>
                                    )
                                }}
                                defaultValue={
                                    selectedFrame !== undefined
                                        ? {
                                              value: selectedFrame,
                                              label: `${selectedFrame.namespace}:${selectedFrame.name}`,
                                          }
                                        : undefined
                                }
                                options={props.availableFramesWithCycleTime.map((it) => {
                                    const label = `${it.namespace}:${it.name}`
                                    return {
                                        value: it,
                                        label,
                                    }
                                })}
                                closeMenuOnSelect={true}
                                hideSelectedOptions={false}
                                components={{
                                    Option,
                                }}
                                onChange={handleChange}
                            />
                        </div>
                    </>
                )}
                {pageState === PageState.ERROR && (
                    <ErrorContainer errorSubText={errorText} errorText={'Signal data error'} />
                )}
                {pageState === PageState.LOADING && <LoadingContainer spinnerSize='sm' />}
                {pageState === PageState.DONE && <div id={props.panelKey.key}></div>}
            </div>
        </>
    )
}

enum PageState {
    LOADING,
    DONE,
    ERROR,
    UPLOADING,
    DELETING,
}
