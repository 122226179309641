import { Dropdown, ButtonGroup, Button } from 'react-bootstrap'

import { AuthenticatedUser, Project, UserBillableUnitInfo } from '../../api/CloudApi/types'
import { NavLink } from 'react-router-dom'
import {
    AddIcon,
    BrokersIcon,
    CredentialsIcon,
    CurrentBillableUnitIcon,
    CurrentProjectIcon,
    DropdownIcon,
    GenericFileIcon,
    HomeIcon,
    LicenseIcon,
    MenuIcon,
    NavbarCollapseIcon,
    NavbarExpandIcon,
    NewProjectIcon,
    ProfileIcon,
    ProjectSettingsIcon,
    QuestionIcon,
    RecordingsIcon,
    SettingsIcon,
    SignalDatabasesIcon,
    UsersIcon,
} from '../../assets/Icons'
import AddProjectModal from '../modals/AddProjectModal'
import { useEffect, useState } from 'react'
import LoadingContainer from '../LoadingContainer'
import UserDetailsModal from '../modals/UserDetailsModal'
import { isDemo, setGuidedDemo } from '../../utils/demo'
import { Permission, hasPermission } from '../../utils/permission'
import ManageAccessTokenModal from '../modals/ManageAccessTokenModal'
import LogoutButton from '../LogoutButton'
import AddBuModal from '../modals/AddBuModal'
import { OrganisationRoutes, ProjectRoutes } from '../../Routes'
import { BreadCrumbs } from '../../types/BreadCrumbs'
import { ConfirmDialogProperties } from '../../types/ConfirmDialogProperties'

interface NavigationBarProps {
    projects: Array<Project>
    billableUnits: Array<UserBillableUnitInfo>
    currentProject: Project | undefined
    currentBillableUnit: UserBillableUnitInfo | undefined
    currentUser: AuthenticatedUser | undefined
    breadCrumbs: BreadCrumbs
    isDemoGuideActive: boolean
    setHelpOverlayFunction?: (newValue: boolean) => void
    setDemoOverlayFunction?: (newValue: boolean) => void
    zIndexOnTop?: boolean
    openConfirmationDialog: (confirmDialogProperties: ConfirmDialogProperties) => void
}

const HIDE_ON_DESKTOP = 'd-block d-lg-none'
const SHOW_ON_DEKSTOP = 'd-none d-lg-block'

const NAVBAR_COLLAPSED_WIDTH = 70
const NAVBAR_EXPANDED_WIDTH = 250
const NAVBAR_COLLPASED_LS_KEY = 'navbar-collapsed'

const DEMO_BAR_X_MARGIN = 55

enum ProjectRouteKey {
    BROKERS = '/brokers',
    FILES = '/files',
    RECORDINGS = '/recordings',
    SIGNAL_DATABASES = '/signal-databases',
    PROJECT_MANAGEMENT = '/settings',
    UNKNOWN = 'UNKNOWN',
}

enum OrganisationRouteKey {
    HOME = '',
    LICENSES = '/licenses',
    USERS = '/users',
    SETTINGS = '/settings',
}

const getCurrentRoute = (): ProjectRouteKey | OrganisationRouteKey => {
    const path = window.location.pathname
    if (path.endsWith(ProjectRouteKey.BROKERS) || path.includes(`${ProjectRouteKey.BROKERS}/`)) {
        return ProjectRouteKey.BROKERS
    }
    if (path.endsWith(ProjectRouteKey.RECORDINGS) || path.includes(`${ProjectRouteKey.RECORDINGS}/`)) {
        return ProjectRouteKey.RECORDINGS
    }
    if (path.endsWith(ProjectRouteKey.SIGNAL_DATABASES)) {
        return ProjectRouteKey.SIGNAL_DATABASES
    }
    if (path.endsWith(ProjectRouteKey.PROJECT_MANAGEMENT)) {
        return ProjectRouteKey.PROJECT_MANAGEMENT
    }
    if (path.endsWith(ProjectRouteKey.FILES)) {
        return ProjectRouteKey.FILES
    }
    if (path.endsWith(OrganisationRouteKey.USERS)) {
        return OrganisationRouteKey.USERS
    }
    if (path.endsWith(OrganisationRouteKey.LICENSES)) {
        return OrganisationRouteKey.LICENSES
    }
    if (path.endsWith(OrganisationRouteKey.SETTINGS)) {
        return OrganisationRouteKey.SETTINGS
    }
    if (path.startsWith('/orgs')) {
        return OrganisationRouteKey.HOME
    }

    return ProjectRouteKey.UNKNOWN
}

export default function NavigationBar(props: NavigationBarProps) {
    const [showUserDetailsModal, setShowUserDetailsModal] = useState<boolean>(false)
    const [showManageTokensModal, setShowManageTokensModal] = useState<boolean>(false)
    const [showAddProjectModal, setShowAddProjectModal] = useState<boolean>(false)
    const [showAddBuModal, setShowAddBuModal] = useState<boolean>(false)
    const [isNavbarCollapsed, setIsNavbarCollapsed] = useState<boolean>(
        localStorage.getItem(NAVBAR_COLLPASED_LS_KEY) === 'true'
    )
    const currentRouteKey = getCurrentRoute()

    useEffect(() => {
        localStorage.setItem(NAVBAR_COLLPASED_LS_KEY, `${isNavbarCollapsed}`)
    }, [isNavbarCollapsed])

    const helpButton = () => {
        if (props.setHelpOverlayFunction !== undefined) {
            return (
                <>
                    <button
                        style={{ height: 29 }}
                        className="d-none d-lg-block remotive-btn-primary rounded-circle border-0"
                        onClick={() => {
                            // This shouldn't be needed since we check this above. But eslint is complaining
                            if (props.setHelpOverlayFunction) {
                                props.setHelpOverlayFunction(true)
                            }
                        }}
                    >
                        <QuestionIcon style={{ paddingBottom: 3 }} sx={{ fontSize: 18 }} />
                    </button>
                </>
            )
        }
        return <></>
    }

    const projectItems = () => {
        return props.projects.map((project) => {
            return (
                <Dropdown.Item
                    className="text-truncate ps-2 text-decoration-none"
                    href={`/p/${project.uid}/recordings`}
                    key={project.uid}
                >
                    {project.displayName}
                </Dropdown.Item>
            )
        })
    }

    const projectDropdown = (variant: 'light' | 'dark', isCollapsed: boolean) => {
        return (
            <Dropdown drop={'down'} as={ButtonGroup} className="w-100 rounded-0">
                <Dropdown.Toggle className="w-100 text-start bg-transparent border-0 p-0 text-truncate d-flex justify-content-between">
                    <div className="d-flex flex-column align-items-center w-100">
                        <div className="d-flex flex-row justify-content-between w-100">
                            <div
                                style={{ paddingLeft: isCollapsed ? 18 : undefined }}
                                className="text-truncate d-flex align-items-center"
                            >
                                <CurrentProjectIcon className={`me-1 remotive-success-50-color`} />{' '}
                                {!isCollapsed && (
                                    <b className="text-truncate">
                                        {props.currentProject?.displayName || 'Select project...'}
                                    </b>
                                )}
                            </div>
                            <DropdownIcon sx={{ fontSize: 20 }} />
                        </div>
                        <div>
                            {isCollapsed && (
                                <p className="m-0 pt-1 remotive-font-xs text-light">
                                    <b>Project</b>
                                </p>
                            )}
                        </div>
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu
                    style={{ minWidth: 100 }}
                    className={`w-auto remotive-font-md p-1 px-0 remotive-dropdown-${variant}`}
                >
                    {projectItems()}
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    const buDropdown = (variant: 'light' | 'dark', isCollapsed: boolean) => {
        return (
            <Dropdown drop={'down'} as={ButtonGroup} className="rounded-0 w-100 mt-2">
                <Dropdown.Toggle
                    className={`w-100 bg-transparent border-0 p-0 text-truncate d-flex justify-content-between align-items-center`}
                >
                    <div className="d-flex flex-column align-items-center w-100">
                        <div className="d-flex flex-row justify-content-between w-100">
                            <div
                                style={{ paddingLeft: isCollapsed ? 18 : undefined }}
                                className="text-truncate d-flex align-items-end"
                            >
                                <CurrentBillableUnitIcon
                                    style={{ marginBottom: 1 }} // Pixel perfect alignment
                                    className={`text-light me-1`}
                                    sx={{ fontSize: 25 }}
                                />
                                {!isCollapsed && (
                                    <b className="remotive-font-md text-light">
                                        {props.currentBillableUnit?.organisation.displayName || 'Loading...'}
                                    </b>
                                )}
                            </div>
                            <DropdownIcon sx={{ fontSize: 20 }} />
                        </div>
                        <div>
                            {isCollapsed && (
                                <p className="m-0 pt-1 remotive-font-xs text-light">
                                    <b>Org.</b>
                                </p>
                            )}
                        </div>
                    </div>
                </Dropdown.Toggle>

                <Dropdown.Menu
                    className={`w-auto min-w-100 remotive-font-md p-1 px-0 remotive-dropdown-${variant}`}
                >
                    {buItems()}
                    {/*!isDemo() && addBuDropdownItem()*/}
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    const buItems = () => {
        const currentBillableUnits = props.billableUnits.map((billableUnit) => {
            return (
                <Dropdown.Item
                    href={`/orgs/${billableUnit.organisation.uid}`}
                    className={`text-truncate ps-2 text-decoration-none text-light`}
                    key={billableUnit.organisation.uid}
                >
                    {billableUnit.organisation.displayName}
                </Dropdown.Item>
            )
        })
        return currentBillableUnits
    }

    const addBuDropdownItem = () => {
        return (
            <>
                <Dropdown.Divider />
                <div className="mx-1">
                    <Dropdown.Item
                        as={Button}
                        onClick={() => setShowAddBuModal(true)}
                        className={`text-truncate ps-2 text-decoration-none remotive-font-sm remotive-btn-md text-white remotive-btn-transparent`}
                        key={'add_bu_dropdown_item'}
                    >
                        <div className="d-flex align-items-center justify-content-center">
                            <AddIcon sx={{ fontSize: 16 }} />
                            <p className="m-0">Add organisation</p>
                        </div>
                    </Dropdown.Item>
                </div>
            </>
        )
    }

    const breadCrumbsTrail = () => {
        return props.breadCrumbs.trail.map((it) => {
            if (it.route === undefined) {
                return (
                    <p key={it.title} className={'text-secondary text-decoration-none me-1'}>
                        {it.title}
                    </p>
                )
            }
            return (
                <NavLink
                    key={`${it.title}-${it.route}`}
                    to={it.route}
                    className={'text-secondary me-1 clickable-underlined-on-hover'}
                >
                    {it.title}
                </NavLink>
            )
        })
    }

    const breadCrumbs = () => {
        return (
            <>
                {breadCrumbsTrail()}
                <p className={'text-dark text-decoration-none d-inline'}>{props.breadCrumbs.current.title}</p>
            </>
        )
    }

    const breadCrumbsWithBuAndProject = () => {
        const isDisplayProjectInfo = window.location.pathname.startsWith('/p')
        const project = props.currentProject?.displayName
        return (
            <div className="text-start">
                <div className="remotive-font-sm text-secondary m-0" style={{ marginTop: '-9px', marginLeft: '34px' }}>
                    {breadCrumbs()}
                </div>
                {isDisplayProjectInfo && (
                    <p className="m-0 p-0 remotive-font-xs text-secondary text-truncate">
                        In project <b>{project}</b>
                    </p>
                )}
            </div>
        )
    }

    const topBar = () => {
        return (
            <>
                <div id="navigation-top-navbar" className="d-flex justify-content-between pe-1 pt-1">
                    <div className="ms-2 mt-1">{breadCrumbsWithBuAndProject()}</div>
                    <div className="p-1 pb-0 d-flex">
                        <div className="me-2">{helpButton()}</div>
                        <Dropdown className="m-0 p-0">
                            <Dropdown.Toggle className="m-0 p-0 bg-transparent border-0 text-truncate">
                                <div className="text-dark d-flex align-items-center p-0 border-0 text-truncate">
                                    <>
                                        {props.currentUser?.picture ? (
                                            <>
                                                <img
                                                    width="29"
                                                    height="29"
                                                    className="rounded-circle me-2 mb-0"
                                                    src={props.currentUser.picture}
                                                ></img>
                                            </>
                                        ) : (
                                            <>
                                                <ProfileIcon className="me-2" sx={{ fontSize: 29 }} />
                                            </>
                                        )}
                                    </>
                                    <div style={{ maxWidth: 150 }} className="text-start text-truncate">
                                        <p className="m-0 remotive-font-md lh-1 text-truncate">{`${
                                            props.currentUser?.firstName || 'Loading...'
                                        } ${props.currentUser?.lastName || ''}`}</p>
                                        <p className="m-0 p-0 remotive-font-xs text-secondary text-truncate">
                                            {props.currentBillableUnit?.organisation.displayName ?? ''}
                                        </p>
                                    </div>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="border-0 shadow w-100">
                                <Dropdown.Item
                                    className="px-2 d-flex align-items-center justify-content-center"
                                    onClick={() => setShowUserDetailsModal(true)}
                                >
                                    <ProfileIcon className="me-2" sx={{ fontSize: 15 }} />
                                    <p className="m-0 remotive-font-md">Profile</p>
                                </Dropdown.Item>
                                {!isDemo() && (
                                    <Dropdown.Item
                                        className="px-2 d-flex align-items-center justify-content-center"
                                        onClick={() => setShowManageTokensModal(true)}
                                    >
                                        <CredentialsIcon className="me-2" sx={{ fontSize: 15 }} />
                                        <p className="m-0 remotive-font-md">Tokens</p>
                                    </Dropdown.Item>
                                )}
                                <Dropdown.ItemText>
                                    <div className="m-0 mt-3 remotive-font-md d-flex align-items-center justify-content-center">
                                        <LogoutButton />
                                    </div>
                                </Dropdown.ItemText>
                            </Dropdown.Menu>
                        </Dropdown>
                        <button
                            className="d-inline-block btn d-flex d-inline p-0 border-0"
                            onClick={() => setShowUserDetailsModal(true)}
                        ></button>
                    </div>
                </div>
            </>
        )
    }

    const getProjectNavOptionDetails = (
        navOptionRouteKey: ProjectRouteKey,
        isCollapsed: boolean,
        isNavItemSelected: boolean
    ) => {
        const commonNavOptionIconClass = ` ${isNavItemSelected ? 'remotive-primary-0-color' : ''}`
        const SMALL_ICON_SIZE = 21
        const REGULAR_ICON_SIZE = 23
        switch (navOptionRouteKey) {
            case ProjectRouteKey.BROKERS:
                return {
                    icon: (
                        <BrokersIcon
                            sx={{ fontSize: isCollapsed ? SMALL_ICON_SIZE : REGULAR_ICON_SIZE }}
                            className={commonNavOptionIconClass}
                        />
                    ),
                    title: isCollapsed ? 'Brokers' : 'Brokers',
                    isEnabled: hasPermission(
                        Permission.PROJECT_VIEWER_BROKER,
                        props.currentBillableUnit,
                        props.currentProject
                    ),
                    route: ProjectRoutes.toBrokersList(props.currentProject?.uid),
                }
            case ProjectRouteKey.RECORDINGS:
                return {
                    icon: (
                        <RecordingsIcon
                            sx={{ fontSize: isCollapsed ? SMALL_ICON_SIZE : REGULAR_ICON_SIZE }}
                            className={commonNavOptionIconClass}
                        />
                    ),
                    title: isCollapsed ? 'Recordings' : 'Recordings',
                    isEnabled: hasPermission(
                        Permission.PROJECT_VIEWER_RECORDING,
                        props.currentBillableUnit,
                        props.currentProject
                    ),
                    route: ProjectRoutes.toRecordingsList(props.currentProject?.uid),
                }
            case ProjectRouteKey.SIGNAL_DATABASES:
                return {
                    icon: (
                        <SignalDatabasesIcon
                            sx={{ fontSize: isCollapsed ? SMALL_ICON_SIZE : REGULAR_ICON_SIZE }}
                            className={commonNavOptionIconClass}
                        />
                    ),
                    title: isCollapsed ? 'Databases' : 'Signal databases',
                    isEnabled: hasPermission(
                        Permission.PROJECT_VIEWER_CONFIG,
                        props.currentBillableUnit,
                        props.currentProject
                    ),
                    route: ProjectRoutes.toSignalDatabases(props.currentProject?.uid),
                }
            case ProjectRouteKey.FILES:
                return {
                    icon: (
                        <GenericFileIcon
                            sx={{ fontSize: isCollapsed ? SMALL_ICON_SIZE : REGULAR_ICON_SIZE }}
                            className={commonNavOptionIconClass}
                        />
                    ),
                    title: isCollapsed ? 'Storage' : 'Storage',
                    isEnabled:
                        hasPermission(
                            Permission.PROJECT_VIEWER_RECORDING,
                            props.currentBillableUnit,
                            props.currentProject
                        ) && isFilesFeatureAllowed(),
                    route: ProjectRoutes.toFiles(props.currentProject?.uid),
                }
            case ProjectRouteKey.PROJECT_MANAGEMENT:
                return {
                    icon: (
                        <ProjectSettingsIcon
                            sx={{ fontSize: isCollapsed ? SMALL_ICON_SIZE : REGULAR_ICON_SIZE }}
                            className={commonNavOptionIconClass}
                        />
                    ),
                    title: isCollapsed ? 'Manage' : 'Manage project',
                    isEnabled: hasPermission(
                        Permission.PROJECT_VIEWER_ADMIN,
                        props.currentBillableUnit,
                        props.currentProject
                    ),
                    route: ProjectRoutes.toSettings(props.currentProject?.uid),
                }

            default:
            case ProjectRouteKey.UNKNOWN:
                console.warn('There is no icon for the current route key')
                return {
                    icon: <></>,
                    title: isCollapsed ? 'N/A' : 'N/A',
                    isEnabled: false,
                    route: '/',
                }
        }
    }

    const projectNavItem = (navOptionRouteKey: ProjectRouteKey, isCollapsed: boolean) => {
        const path = window.location.pathname
        const isNavItemSelected = path.startsWith('/p') && currentRouteKey === navOptionRouteKey
        const navOptionDetails = getProjectNavOptionDetails(navOptionRouteKey, isCollapsed, isNavItemSelected)
        const content = (
            <div className={`d-flex align-items-center ${isCollapsed ? 'flex-column' : ''}`}>
                {navOptionDetails.icon}
                <p className={`m-0 p-0 ${isCollapsed ? 'remotive-font-xxs mt-1' : 'remotive-font-md ms-2'}`}>
                    {isNavItemSelected ? (
                        <b className="remotive-primary-0-color">{navOptionDetails.title}</b>
                    ) : (
                        <span className="remotive-primary-0-color">{navOptionDetails.title}</span>
                    )}
                </p>
            </div>
        )
        if (navOptionDetails.isEnabled) {
            return (
                <NavLink
                    to={navOptionDetails.route}
                    className={`btn remotive-btn-nav mb-1 border-0 d-flex align-items-center ${
                        isCollapsed ? 'flex-column' : 'justify-content-start ps-4'
                    }`}
                >
                    {content}
                </NavLink>
            )
        }
        return (
            <button
                disabled
                className={`btn remotive-btn-nav mb-1 border-0 d-flex align-items-center ${
                    isCollapsed ? 'flex-column justify-content-center' : 'justify-content-start ps-4'
                }`}
            >
                {content}
            </button>
        )
    }

    const isFilesFeatureAllowed = () => {
        return (
            props.currentBillableUnit?.organisation.uid === 'remotivelabs' ||
            (props.currentBillableUnit?.organisation.uid === 'vcc' &&
                (props.currentProject?.uid === 'vcc-hallered' || props.currentProject?.uid === 'durability-lab'))
        )
    }

    const projectNavigation = (variant: 'light' | 'dark', isCollapsed: boolean) => {
        return (
            <>
                <div className={`d-flex justify-content-center ${isCollapsed ? 'flex-column mb-2' : 'mb-3 '}`}>
                    <div className={`w-100 ${isCollapsed ? '' : 'px-3'}`}>{projectDropdown(variant, isCollapsed)}</div>
                </div>
                <div className={`d-flex flex-column`}>
                    {projectNavItem(ProjectRouteKey.RECORDINGS, isCollapsed)}
                    {projectNavItem(ProjectRouteKey.SIGNAL_DATABASES, isCollapsed)}
                    {projectNavItem(ProjectRouteKey.BROKERS, isCollapsed)}
                    {isFilesFeatureAllowed() && projectNavItem(ProjectRouteKey.FILES, isCollapsed)}

                    {!isDemo() && // Hide this nav option when in demo mode
                        projectNavItem(ProjectRouteKey.PROJECT_MANAGEMENT, isCollapsed)}
                </div>
            </>
        )
    }

    const getBuNavOptionDetails = (buNavOptionRouteKey: OrganisationRouteKey, isNavItemSelected: Boolean) => {
        const selectedNavOptionIconClass = ` ${isNavItemSelected ? 'remotive-primary-70-color' : ''}`
        switch (buNavOptionRouteKey) {
            case OrganisationRouteKey.USERS:
                return {
                    icon: <UsersIcon className={selectedNavOptionIconClass} sx={{ fontSize: 15 }} />,
                    shortTitle: 'Users',
                    title: 'Users',
                    isEnabled: hasPermission(
                        Permission.ORG_VIEWER_USER,
                        props.currentBillableUnit,
                        props.currentProject
                    ),
                    route: OrganisationRoutes.toUsers(props.currentBillableUnit?.organisation.uid),
                }

            case OrganisationRouteKey.LICENSES:
                return {
                    icon: <LicenseIcon className={selectedNavOptionIconClass} sx={{ fontSize: 15 }} />,
                    shortTitle: 'Licenses',
                    title: 'Licenses',
                    isEnabled: hasPermission(
                        Permission.ORG_VIEWER_LICENSE,
                        props.currentBillableUnit,
                        props.currentProject
                    ),
                    route: OrganisationRoutes.toLicenses(props.currentBillableUnit?.organisation.uid),
                }

            case OrganisationRouteKey.SETTINGS:
                return {
                    icon: <SettingsIcon className={selectedNavOptionIconClass} sx={{ fontSize: 15 }} />,
                    shortTitle: 'Settings',
                    title: 'Settings',
                    isEnabled: hasPermission(
                        Permission.ORG_VIEWER_LICENSE,
                        props.currentBillableUnit,
                        props.currentProject
                    ),
                    route: OrganisationRoutes.toSettings(props.currentBillableUnit?.organisation.uid),
                }

            default:
            case OrganisationRouteKey.HOME:
                return {
                    icon: <HomeIcon className={selectedNavOptionIconClass} sx={{ fontSize: 15 }} />,
                    shortTitle: 'Home',
                    title: 'Home',
                    isEnabled: true, // The organisation home is always active if you belong to an organisation
                    route: OrganisationRoutes.toHome(props.currentBillableUnit?.organisation.uid),
                }
        }
    }

    const buNavItem = (buNavOptionRouteKey: OrganisationRouteKey, isCollapsed: boolean) => {
        const path = window.location.pathname
        const isNavItemSelected = path.startsWith('/orgs') && currentRouteKey === buNavOptionRouteKey
        const buNavOptionDetails = getBuNavOptionDetails(buNavOptionRouteKey, isNavItemSelected)
        return (
            <>
                <div
                    style={{ minWidth: 50, cursor: buNavOptionDetails.isEnabled ? 'default' : 'not-allowed' }}
                    className={`d-flex flex-column not-allowed ${isCollapsed ? 'pt-2' : 'ps-0'} mb-0`}
                >
                    <button
                        disabled={!buNavOptionDetails.isEnabled}
                        className={`btn border-0 px-0 py-0 justify-content-center`}
                    >
                        <NavLink
                            to={buNavOptionDetails.route}
                            className={'d-flex flex-column align-items-center text-light text-decoration-none'}
                        >
                            <>
                                <div
                                    style={{ height: 25, width: 25 }}
                                    className={`btn d-flex align-items-center justify-content-center rounded-circle p-0 m-0 remotive-btn ${
                                        isNavItemSelected
                                            ? 'remotive-primary-0-background'
                                            : 'remotive-primary-70-background remotive-primary-0-color'
                                    }`}
                                >
                                    {buNavOptionDetails.icon}
                                </div>
                                <p
                                    className={`m-0 d-inline text-light  mt-1 align-middle  ${
                                        isCollapsed ? 'remotive-font-xxs' : 'remotive-font-xs'
                                    }`}
                                >
                                    {buNavOptionDetails.title}
                                </p>
                            </>
                        </NavLink>
                    </button>
                </div>
            </>
        )
    }

    const organisationNavigation = (variant: 'light' | 'dark', isCollapsed: boolean) => {
        return (
            <>
                <div className={`d-flex mt-3 ${isCollapsed ? 'flex-column mb-2' : 'px-4 mb-4'}`}>
                    <div className="w-100">{buDropdown(variant, isCollapsed)}</div>
                </div>
                <div
                    className={`d-flex mb-2 ${
                        isCollapsed
                            ? 'flex-column'
                            : isDemo()
                            ? 'justify-content-start flex-row px-2'
                            : 'justify-content-evenly flex-row px-2'
                    }`}
                >
                    {buNavItem(OrganisationRouteKey.HOME, isCollapsed)}
                    {!isDemo() && // Hide this nav option when in demo mode
                        buNavItem(OrganisationRouteKey.USERS, isCollapsed)}
                    {!isDemo() && // Hide this nav option when in demo mode
                        buNavItem(OrganisationRouteKey.LICENSES, isCollapsed)}
                    {!isDemo() && // Hide this nav option when in demo mode
                        buNavItem(OrganisationRouteKey.SETTINGS, isCollapsed)}
                </div>
            </>
        )
    }

    const collapseOrExpandButton = () => {
        return (
            <div className="d-flex justify-content-end me-3">
                <button
                    onClick={() => setIsNavbarCollapsed(!isNavbarCollapsed)}
                    className="btn px-1 remotive-btn-sm  remotive-primary-10-color"
                >
                    {isNavbarCollapsed ? (
                        <NavbarExpandIcon sx={{ fontSize: 20 }} />
                    ) : (
                        <NavbarCollapseIcon sx={{ fontSize: 20 }} />
                    )}
                </button>
            </div>
        )
    }

    const getSidebarBody = () => {
        if (props.currentBillableUnit === undefined) {
            return (
                <LoadingContainer
                    spinnerSize="sm"
                    variant="white"
                    loadingText={isNavbarCollapsed ? ' ' : 'Loading...'}
                />
            )
        }
        return (
            <div className="d-flex align-items-start flex-column h-100">
                <div className="mb-auto w-100">
                    {props.projects.length > 0 && projectNavigation('dark', isNavbarCollapsed)}
                    {props.projects.length === 0 && (
                        <p className={'mx-0 ms-1 remotive-font-sm d-inline align-center text-white'}>
                            {isNavbarCollapsed ? 'No projects' : 'You have no projects available'}
                        </p>
                    )}
                    {!isDemo() && ( // Hide this nav option when in demo mode
                        <div className={`d-flex justify-content-center`}>
                            <button
                                className={`btn ${
                                    isNavbarCollapsed ? 'remotive-btn-sm mt-3' : 'remotive-btn px-5 mt-5'
                                } remotive-btn-transparent text-white d-flex align-items-center justify-content-center`}
                                onClick={() => setShowAddProjectModal(true)}
                                disabled={
                                    !hasPermission(
                                        Permission.ORG_EDITOR_PROJECT,
                                        props.currentBillableUnit,
                                        props.currentProject
                                    )
                                }
                            >
                                <NewProjectIcon sx={{ fontSize: 18 }} />
                                {!isNavbarCollapsed && <p className="m-0 ms-1 remotive-font-sm">Add project</p>}
                            </button>
                        </div>
                    )}
                </div>
                <div className="mb-0 w-100">{organisationNavigation('dark', isNavbarCollapsed)}</div>
                <div className="w-100 text-end">{collapseOrExpandButton()}</div>
            </div>
        )
    }

    const sideBar = () => {
        return (
            <div
                className={`${SHOW_ON_DEKSTOP} remotive-gradient shadow rounded-4 rounded-end-5 m-2`}
                style={{
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    marginRight: -15, // Gives room for the scrollbar when window height is low
                    height: '98%',
                    zIndex: props.zIndexOnTop ? 9999 : 'inherit',
                    width: isNavbarCollapsed ? NAVBAR_COLLAPSED_WIDTH : NAVBAR_EXPANDED_WIDTH,
                }}
            >
                <div className="d-flex flex-column h-100">
                    <div className={`navbar-header mt-3 ${isNavbarCollapsed ? 'mb-2' : 'mb-5'}  text-start`}>
                        <NavLink
                            to={OrganisationRoutes.toHome(props.currentBillableUnit?.organisation.uid)}
                            className={'text-decoration-none'}
                        >
                            {!isNavbarCollapsed && (
                                <div className="navbar-brand d-flex justify-content-start ms-3">
                                    <img width="auto" height="30" src="/remotive-labs-logo-neg.png" />
                                </div>
                            )}
                            {isNavbarCollapsed && (
                                <div className="navbar-brand d-flex justify-content-center">
                                    <img width="auto" height="30" src="/remotivelabs-logo_icon-neg_low.png" />
                                </div>
                            )}
                        </NavLink>
                    </div>
                    <div className="pt-3 h-100">{getSidebarBody()}</div>
                </div>
            </div>
        )
    }

    const bottomBarMenu = () => {
        return (
            <div className="text-center d-flex flex-column mx-2">
                <Dropdown as={ButtonGroup} className="rounded-0 border-0" drop={'up'} autoClose="outside">
                    <Dropdown.Toggle
                        className={`w-100 text-center text-light bg-transparent border-0 p-0 text-truncate`}
                    >
                        <div className="remotive-success-50-color d-block">
                            <MenuIcon />
                        </div>
                        <p className="text-light remotive-font-xs m-0 d-block">Menu</p>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="remotive-font-md px-3 pb-1 pt-4 mb-1 remotive-dropdown-dark">
                        {projectNavigation('light', false)}
                        <div>
                            <div className="mt-4 mb-5">
                                <button
                                    className="btn remotive-btn text-white remotive-btn-transparent w-100 p-1"
                                    onClick={() => setShowAddProjectModal(true)}
                                    disabled={
                                        !hasPermission(
                                            Permission.ORG_EDITOR_USER,
                                            props.currentBillableUnit,
                                            props.currentProject
                                        )
                                    }
                                >
                                    <NewProjectIcon className="align-center" sx={{ fontSize: 18 }} />
                                    <p className="m-0 ms-1 remotive-font-sm d-inline align-center">Add project</p>
                                </button>
                            </div>
                        </div>
                        <div className="">{organisationNavigation('light', false)}</div>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        )
    }

    const bottomBar = () => {
        return (
            <div
                className={
                    HIDE_ON_DESKTOP +
                    ' remotive-gradient-sm px-4 pt-1 pb-2 fixed-bottom d-flex justify-content-between align-items-center rounded-4 m-1'
                }
            >
                {/* This is hidden because we want to use justify-content-between and to make the layout even the first and last item must be identical in size */}
                <div className="invisible">{bottomBarMenu()}</div>
                <div className="d-none d-sm-block">
                    <NavLink
                        to={OrganisationRoutes.toHome(props.currentBillableUnit?.organisation.uid)}
                        className={'text-decoration-none'}
                    >
                        <img width="auto" height="30" src="/remotive-labs-logo-neg.png" />
                    </NavLink>
                </div>
                <div className="d-block d-sm-none">
                    <NavLink
                        to={OrganisationRoutes.toHome(props.currentBillableUnit?.organisation.uid)}
                        className={'text-decoration-none'}
                    >
                        <img width="auto" height="30" src="/remotivelabs-logo_icon-neg_low.png" />
                    </NavLink>
                </div>
                <div>{bottomBarMenu()}</div>
            </div>
        )
    }

    const activateGuidedMode = () => {
        if (props.setDemoOverlayFunction) {
            setGuidedDemo(true)
            props.setDemoOverlayFunction(true)
        }
    }

    const deactivateGuidedMode = () => {
        if (props.setDemoOverlayFunction) {
            setGuidedDemo(false)
            props.setDemoOverlayFunction(false)
        }
    }

    const demoBar = () => {
        if (isDemo() && props.setDemoOverlayFunction) {
            return (
                <>
                    <div
                        className={`${SHOW_ON_DEKSTOP} remotive-primary-60-background p-1 fixed-bottom rounded-4`}
                        style={{
                            zIndex: props.isDemoGuideActive ? 9999 : 1,
                            marginLeft: isNavbarCollapsed
                                ? NAVBAR_COLLAPSED_WIDTH + DEMO_BAR_X_MARGIN
                                : NAVBAR_EXPANDED_WIDTH + DEMO_BAR_X_MARGIN,
                            marginRight: DEMO_BAR_X_MARGIN,
                            marginBottom: 7,
                        }}
                    >
                        {!props.isDemoGuideActive && (
                            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: 36 }}>
                                <p className="m-0 text-light remotive-font-md">Need help? </p>
                                <button
                                    className="btn remotive-btn-success remotive-btn-sm"
                                    onClick={() => activateGuidedMode()} // fallback to help mode if the page doesn't have a demo mode
                                >
                                    Guide me
                                </button>
                            </div>
                        )}
                        {props.isDemoGuideActive && (
                            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: 36 }}>
                                <button
                                    className="btn btn-danger remotive-btn-sm"
                                    onClick={() => deactivateGuidedMode()} // fallback to help mode if the page doesn't have a demo mode
                                >
                                    Exit guide
                                </button>
                            </div>
                        )}
                    </div>
                </>
            )
        }
        return <></>
    }

    return (
        <>
            {/* Navigation on large screens */}
            <div
                className="d-flex d-none d-lg-block"
                style={{ marginRight: isNavbarCollapsed ? NAVBAR_COLLAPSED_WIDTH + 8 : NAVBAR_EXPANDED_WIDTH + 8 }}
            >
                <div
                    className="h-100 position-fixed d-flex align-items-center"
                    style={{ zIndex: props.zIndexOnTop ? 9999 : 11 }}
                >
                    {sideBar()}
                </div>
                <div
                    className="w-100 position-absolute"
                    style={{
                        paddingLeft: isNavbarCollapsed ? NAVBAR_COLLAPSED_WIDTH + 8 : NAVBAR_EXPANDED_WIDTH + 8,
                        zIndex: 10,
                    }}
                >
                    {topBar()}
                </div>
                <div className="position-fixed" style={{ zIndex: props.isDemoGuideActive ? 9998 : 9 }}>
                    {demoBar()}
                </div>
            </div>
            {/* Navigation on small screens */}
            <div>
                <div className="d-block d-lg-none position-bottom" style={{ zIndex: 9996 }}>
                    {bottomBar()}
                    <div className="w-100 position-absolute">{topBar()}</div>
                </div>
            </div>
            {/* Modals below */}
            <AddProjectModal
                currentBillableUnit={props.currentBillableUnit}
                show={showAddProjectModal}
                handleCloseFunction={() => setShowAddProjectModal(false)}
            />
            <AddBuModal show={showAddBuModal} handleCloseFunction={() => setShowAddBuModal(false)} />
            <UserDetailsModal
                currentBillableUnit={props.currentBillableUnit}
                currentProject={props.currentProject}
                currentUser={props.currentUser}
                show={showUserDetailsModal}
                handleCloseFunction={() => setShowUserDetailsModal(false)}
            />
            <ManageAccessTokenModal
                currentProject={undefined}
                currentServiceAccount={undefined}
                show={showManageTokensModal}
                handleCloseFunction={() => setShowManageTokensModal(false)}
                openConfirmationDialog={props.openConfirmationDialog}
            />
        </>
    )
}
