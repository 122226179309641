import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { Spinner, Accordion } from 'react-bootstrap'
import {
    ProcessingRecordingFile,
    ProcessingRecordingFileStatus,
    Project,
    RecordingSession,
    UserBillableUnitInfo,
} from '../api/CloudApi/types'
import CloudApi from '../api/CloudApi'
import ProcessingRecordingListItem from './ProcessingRecordingListItem'
import useInterval from '../hooks/useInterval'

interface ProcessingRecordingsAccordionProps {
    componentKey: string
    project: Project
    billableUnit: UserBillableUnitInfo
    onProcessingFinishedCallback: Function
    parentRecordingSession?: RecordingSession
}

const PROCESSING_RECORDINGS_POLLING_INTERVAL = 1_000

export default function ProcessingRecordingsAccordion(props: ProcessingRecordingsAccordionProps) {
    const [processingRecordings, setProcessingRecordings] = useState<Array<ProcessingRecordingFile>>([])
    const isPollingActive = useRef<boolean>(false)
    const previousProcessingRecordings = useRef(processingRecordings)

    useEffect(() => {
        fetchProcessingRecordings(props.project)
    }, [props.componentKey])

    useInterval(() => {
        if (isPollingActive.current) {
            fetchProcessingRecordings(props.project)
        }
    }, PROCESSING_RECORDINGS_POLLING_INTERVAL)

    useEffect(() => {
        // Keep polling for processing recordings
        isPollingActive.current =
            processingRecordings.filter(
                (it) =>
                    it.status === ProcessingRecordingFileStatus.RUNNING ||
                    it.status === ProcessingRecordingFileStatus.READY
            ).length > 0

        if (previousProcessingRecordings.current.length !== processingRecordings.length) {
            props.onProcessingFinishedCallback()
        }
        previousProcessingRecordings.current = processingRecordings
    }, [processingRecordings])

    const fetchProcessingRecordings = async (project: Project) => {
        const processing = await CloudApi.listProcessingRecordingFiles(project.uid)

        setProcessingRecordings(
            processing.data
                .filter((it) => it.status !== ProcessingRecordingFileStatus.DONE)
                .filter((it) =>
                    props.parentRecordingSession !== undefined
                        ? it.parentRecordingSessionId === props.parentRecordingSession.sessionId
                        : true
                )
        )
    }

    const processingRecordingsAccordion = () => {
        return (
            <Accordion flush defaultActiveKey={'0'} className="p-2 px-3">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <div className="d-flex align-items-center">
                            <p className={'remotive-font-md m-0 p-0'}>
                                <b>Processing recordings</b>
                            </p>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body className={'flex-box align-content-start p-0 pt-1'}>
                        <>{processingRecordingsList()}</>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        )
    }

    const processingRecordingsToDisplay = processingRecordings.filter(
        (it) =>
            it.status === ProcessingRecordingFileStatus.RUNNING || it.status === ProcessingRecordingFileStatus.READY || it.status === ProcessingRecordingFileStatus.FAILED
    )

    const processingRecordingsList = () => {
        if (processingRecordingsToDisplay.length > 0) {
            return (
                <>
                    {processingRecordingsToDisplay
                        .sort((recordingA, recordingB) => recordingA.errors.length - recordingB.errors.length)
                        .map((recording) => {
                            return (
                                <div key={`${props.componentKey}-${recording.uploadId}`}>
                                    <ProcessingRecordingListItem
                                        background="white"
                                        recording={recording}
                                        billableUnit={props.billableUnit}
                                        project={props.project}
                                        refreshProcessingRecordingState={() => fetchProcessingRecordings(props.project)}
                                    />
                                </div>
                            )
                        })}
                </>
            )
        } else {
            return <></>
        }
    }

    const processingRecordingsSection = () => {
        return processingRecordings.length > 0 ? (
            <div className="mb-1 remotive-primary-10-background rounded-3">{processingRecordingsAccordion()}</div>
        ) : (
            <></>
        )
    }

    return processingRecordingsSection()
}
