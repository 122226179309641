import Highcharts, { Chart, HTMLDOMElement } from 'highcharts/highstock'
import HighchartsBoost from 'highcharts/modules/boost'
import Exporting from 'highcharts/modules/exporting'
import Accessibility from 'highcharts/modules/accessibility'
import CloudApi from '../../../api/CloudApi'
import { useEffect, useRef, useState } from 'react'
import {
    FrameEntry,
    Project,
    RecordingSession,
    SignalEntry,
    SignalIdentifier,
    SignalList,
    SignalListEntry,
    SignalMetadata,
} from '../../../api/CloudApi/types'
import { toast } from 'react-toastify'
import LoadingContainer from '../../../components/LoadingContainer'
import ErrorContainer from '../../../components/ErrorContainer'
import { CircleRounded, CloseRounded, RuleRounded } from '@mui/icons-material'
import { ANALYTICS_CHART_TYPE_KEY, AnalyticsPanelType, PanelKey, TimeSeriesPanel } from './Types'
import { Form, Spinner } from 'react-bootstrap'
import { formattedToastMessage } from '../../../utils/toast'
import SelectSignalsModal from '../SelectSignalsModal'
import SignalInChartLabel from './SignalInChartLabel'
import { isLocalOrDevEnvironment } from '../../../utils/environment'

HighchartsBoost(Highcharts)

interface TimeSeriesContainerProps {
    updatePanel: (panel: TimeSeriesPanel) => void
    removeThisPanelFunction: () => void
    currentProject: Project | undefined
    recordingSessionId: string | undefined
    recordingSession: RecordingSession | undefined
    selectedSignals: Array<SignalEntry>
    hiddenSignals: Array<SignalEntry>
    availableFrameEntries: Array<FrameEntry>
    panelKey: PanelKey
}

export default function TimeSeriesContainer(props: TimeSeriesContainerProps) {
    Exporting(Highcharts)
    Accessibility(Highcharts)

    const [componentState, setComponentState] = useState(ComponentState.LOADING)
    const [selectableSignals, setSelectableSignals] = useState<Array<FrameEntry>>(props.availableFrameEntries)
    const [selectedSignals, setSelectedSignals] = useState<Array<SignalEntry>>(props.selectedSignals ?? [])
    const [hiddenSignals, setHiddenSignals] = useState<Array<SignalEntry>>(props.hiddenSignals ?? [])
    const [showSelectSignalsModal, setShowSelectSignalsModal] = useState<boolean>(false)
    const [signalDataList, setSignalDataList] = useState<SignalList>()
    const [plotLines, setPlotLifnes] = useState<Array<any>>([])
    const [chart, setChart] = useState<Chart | undefined>(undefined)
    const [showYAxis, setShowYAxis] = useState(true)
    const signalColorsInChart = useRef<Map<string, string | Highcharts.GradientColorObject | Highcharts.PatternObject>>(
        new Map()
    )

    useEffect(() => {
        console.log('Mounting chart!')
    }, [])

    useEffect(() => {
        console.log(`Chart is ${chart}`)
        return () => {
            console.log('Destoying chart!')
            chart?.destroy()
        }
    }, [chart])

    useEffect(() => {
        setSelectableSignals(props.availableFrameEntries)
        setComponentState(ComponentState.DONE)
    }, [props.availableFrameEntries])

    useEffect(() => {
        if (props.recordingSessionId && (selectedSignals.length > 0 || hiddenSignals.length > 0)) {
            const panelToStore = { panelKey: props.panelKey, selectedSignals, hiddenSignals } as TimeSeriesPanel
            props.updatePanel(panelToStore)
        }
    }, [selectedSignals, hiddenSignals])

    useEffect(() => {
        if (chart?.xAxis?.[0]) {
            chart.xAxis[0].update({})
        }
    }, [plotLines])

    useEffect(() => {
        if (chart?.series) {
            try {
                chart.series.forEach((series) => {
                    const isNotAHiddenSignal =
                        hiddenSignals.find((signalEntry) => {
                            return constructSignalNameKeyFromSignalEntry(signalEntry) === series.options.id
                        }) === undefined
                    series.setVisible(isNotAHiddenSignal, false)
                })
                chart.redraw()
            } catch (e: any) {
                toast.error(
                    formattedToastMessage(
                        'Dashboard error',
                        'Something went wrong when trying to hide/show a signal in a time series graph.'
                    )
                )
            }
        }
    }, [hiddenSignals, chart])

    useEffect(() => {
        if (signalDataList && signalDataList.signals.length > 0) {
            renderGraph()
            if (signalDataList.signals.length !== selectedSignals.length) {
                toast.warn(
                    formattedToastMessage(
                        'Graph issue',
                        "We couldn't find data points for all your currently selected signals. The signals without data points have been disabled."
                    ),
                    { autoClose: 20_000 }
                )
            }
        }
    }, [signalDataList])

    useEffect(() => {
        if (selectedSignals.length > 0) {
            loadSignalData()
        } else {
            chart?.destroy()
            setSignalDataList(undefined)
        }
    }, [selectedSignals])

    useEffect(() => {
        chart?.yAxis?.forEach(function (y: any) {
            y.update(
                {
                    visible: showYAxis,
                },
                false
            ) // false means don't redraw after each update
        })
        chart?.redraw()
    }, [showYAxis])

    /**
     * Override the reset function, we don't need to hide the tooltips and
     * crosshairs.
     */
    Highcharts.Pointer.prototype.reset = function () {
        return undefined
    }

    /**
     * Highlight a point by showing tooltip, setting hover state and draw crosshair
     */
    // @ts-ignore
    /*Highcharts.Point.prototype.highlight = function (event: any) {
            event = this.series.chart.pointer.normalize(event)
            this.onMouseOver() // Show the hover marker
            this.series.chart.tooltip.refresh(this) // Show the tooltip
            this.series.chart.xAxis[0].drawCrosshair(event, this) // Show the crosshair
        }*/

    function syncExtremes(e: any) {
        // @ts-ignore

        const thisChart = (this as any).chart

        // Only render timeseries charts
        function isTimeseriesChart(chart: any): boolean {
            if (chart !== thisChart && chart !== undefined) {
                const highchartContainer = chart.container.parentElement
                return (
                    highchartContainer &&
                    highchartContainer.getAttribute(ANALYTICS_CHART_TYPE_KEY) === AnalyticsPanelType.SIGNAL_TIME_SERIES
                )
            }
            return false
        }

        if (e.trigger !== 'syncExtremes') {
            // Prevent feedback loop
            Highcharts.each(Highcharts.charts, function (chart: Highcharts.Chart | undefined) {
                //console.log('X' + JSON.stringify(chart))
                if (isTimeseriesChart(chart)) {
                    chart!.xAxis?.[0].setExtremes(e.min, e.max, undefined, false, { trigger: 'syncExtremes' })
                }
            })
        }
    }

    const getSignalMetadata = (entry: SignalListEntry): SignalMetadata | undefined => {
        const metadata = signalDataList?.metadata?.filter((m) => m.name === entry.name)
        if (metadata && metadata.length > 0) {
            return metadata[0]
        }
        return undefined
    }

    const plotMinMaxBands = (entry: SignalListEntry) => {
        const metadata = signalDataList?.metadata?.filter((m) => m.name == entry.name)
        if (metadata && metadata.length > 0) {
            return [
                {
                    color: '#EFEFEF', // Color value
                    from: metadata[0].min,
                    to: metadata[0].max,
                    //from: 3, // Start of the plot band
                    //to: 4, // End of the plot band
                },
            ]
        }
    }

    const renderGraph = () => {
        const sl = signalDataList as SignalList
        //const a = [1, 2]
        //.forEach((n) => console.log(n))

        var chartDiv: HTMLDOMElement = document.createElement('div')
        chartDiv.className = 'chart'
        chartDiv.setAttribute(ANALYTICS_CHART_TYPE_KEY, AnalyticsPanelType.SIGNAL_TIME_SERIES)
        document.getElementById(props.panelKey.key)!.appendChild(chartDiv)

        const series = sl.signals.map((entry, i) => {
            const metadata = getSignalMetadata(entry)
            const [frameName, signalName] = entry.name.split('.')
            const unit = metadata?.unit ? `(${metadata.unit})` : ''
            const color = Highcharts.getOptions().colors![i]
            const signalKey = constructSignalNameKey(signalName, frameName, metadata?.namespace ?? 'N/A')
            signalColorsInChart.current.set(signalKey, color)
            const attributes = {
                dataGrouping: {
                    enabled: false,
                },
                boostThreshold: 1000,
                id: signalKey,
                name: `${entry.name} ${unit}`,
                data: entry.signals,
                selected: true,
                yAxis: i,
                color: color,
                tooltip: {
                    valueDecimals: 5,
                },
            }
            return attributes
        })

        const yAxis = sl.signals.map((entry, i) => {
            const a = {
                //showLastLabel: true,
                visible: showYAxis,
                crosshair: true,
                lineWidth: 1,
                opposite: i % 2 === 0,
                title: {
                    text: entry.name,
                    style: {
                        color: Highcharts.getOptions().colors![i],
                    },
                },
            }
            return a
        })

        const options: any = {
            credits: {
                enabled: false,
            },
            boost: {
                debug: { showSkipSummary: true },
                //usePreAllocated: true,
                //useGPUTranslations: true,
            },
            chart: {
                backgroundColor: '#fff',
                panning: true,
                panKey: 'shift',
                zoomType: 'x',
                height: '400',
                custom: {
                    chartKey: props.panelKey,
                },
                events: {
                    click: function (event: any) {
                        /*
                        console.log(event)
                        //console.log(typeof event)
                        const label = window.prompt('Add text to selected point')
                        if (label == null || label == '') {
                            return
                        }
                        //const a = (chart?.xAxis[0] as any).plotLinesAndBands as Array<PlotLineOrBand>
                        //a.forEach((n) => console.log(n.options))

                        //                        console.log(`x = ${chart?.hoverPoint?.x}, y = ${chart?.hoverPoint?.y}`)
                        if (chart) {
                            const plotLine = chart?.xAxis[0].addPlotLine({
                                zIndex: 20,
                                color: '#0b8551', // Color value
                                dashStyle: 'ShortDash',
                                //dashStyle: 'LongDashDotDot', // Style of the plot line. Default to solid

                                value: chart!.hoverPoint!.x!,
                                //value: event.xAxis[0].value, // Value of where the line will appear
                                width: 2, // Width of the line
                                label: {
                                    text: label!,
                                },

                                //id: 'asdf',
                                //acrossPanes: true,
                            })

                            const x = chart.xAxis[0].toPixels(event.xAxis[0].value, false)
                            const y = chart.yAxis[0].toPixels(chart.yAxis[0].min!, false)
                            console.log(`x = ${x}, y = ${y} min = ${chart.yAxis[0].min}`)
                            //chart.renderer.circle(x, y, 3).add()
                            //}
                        }
                        chart?.xAxis[0].update({}, true)
                        //chart?.yAxis[0].update({}, true)
                        chart?.update({}, true, true)
                        //chart?.redraw()
                        */
                    },
                },
            },

            responsive: {
                rules: [
                    {
                        condition: {
                            maxWidth: 400,
                            maxHeight: 200,
                        },
                        chartOptions: {
                            legend: {
                                align: 'center',
                                verticalAlign: 'bottom',
                                layout: 'horizontal',
                            },
                        },
                    },
                ],
            },

            navigator: { enabled: true }, //i === sl.signals.length - 1 ? { enabled: true } : { enabled: false },

            rangeSelector: {
                buttons: [
                    {
                        type: 'second',
                        count: 5,
                        text: '5s',
                    },
                    {
                        type: 'second',
                        count: 10,
                        text: '10s',
                    },
                    {
                        type: 'second',
                        count: 30,
                        text: '30s',
                    },
                    {
                        type: 'minute',
                        count: 1,
                        text: '1min',
                    },
                    {
                        type: 'minute',
                        count: 5,
                        text: '5m',
                    },
                    {
                        type: 'all',
                        text: 'All',
                    },
                ],
                inputEnabled: false, // it supports only days
                selected: 5,
            },

            legend: {
                enabled: false,
            },

            series: series,

            plotOptions: {
                flags: {
                    accessibility: {
                        exposeAsGroupOnly: true,
                        description: 'Flagged events.',
                    },
                },
                line: {
                    marker: {
                        enabled: true,
                    },
                },
            },
            yAxis: yAxis,

            //plotBands: plotMinMaxBands(entry),
            xAxis: {
                type: 'linear',
                crosshair: true,
                events: {
                    setExtremes: syncExtremes,
                },
            },
        }

        Highcharts.stockChart(chartDiv, options, (loadedChart) => setChart(loadedChart))
        /*
        const b = ['mousemove', 'touchmove', 'touchstart']
        b.forEach(function (eventType: any) {
            document.getElementById(props.panelKey)!.addEventListener(eventType, function (e) {
                var chart, point, i, event

                for (i = 0; i < Highcharts.charts.length; i = i + 1) {
                    chart = Highcharts.charts[i]
                    // Find coordinates within the chart
                    event = chart!.pointer.normalize(e)
                    // Get the hovered point
                    //chart!.series.forEach((s) => {})

                    //console.log(chart!.series)

                    point = chart!.series[0].searchPoint(event, true)
                    if (point) {
                        // @ts-ignore
                        point.highlight(e)
                    }
                }
            })
        })
        */
    }

    const onError = (err: any) => {
        console.error(err)
        setComponentState(ComponentState.ERROR)
    }

    /*
        We can currently only crunch recordings made with CAN/DBC
     */
    const recordingContainsOnlyCANrecordings = () => {
        if (props.recordingSession) {
            const canRecordings = props.recordingSession.recordings.filter((r) =>
                r.metadata?.database?.endsWith('.dbc')
            )
            return canRecordings.length === props.recordingSession.recordings.length
        }
        return false
    }

    const exportSignalData = async () => {
        //setComponentState(ComponentState.LOADING)
        //setSignalDataList(undefined)
        if (selectedSignals.length > 0) {
            const signalNames = selectedSignals?.map((s) => `${s.namespace}:${s.frameName}.${s.name}`)
            CloudApi.exportTimeseries(props.currentProject!.uid, props.recordingSessionId!, signalNames)
                .then((res) => {
                    //setComponentState(ComponentState.DONE)
                    // TODO - How to show link to user? As link, open tab, redirect?
                    window.open(`/p/${props.currentProject?.uid}/files/?currentPath=${res.data}`, '_blank')
                })
                .catch(onError)
        } else {
            setComponentState(ComponentState.DONE)
        }
    }

    const loadSignalData = async () => {
        setComponentState(ComponentState.LOADING)
        setSignalDataList(undefined)
        if (selectedSignals.length > 0) {
            const signalNames: Array<SignalIdentifier> = selectedSignals?.map((s) => ({
                namespace: s.namespace,
                frameName: s.frameName,
                signalName: s.name,
            }))
            CloudApi.getSignalsTimeseries(props.currentProject!.uid, props.recordingSessionId!, signalNames)
                .then((res) => {
                    setComponentState(ComponentState.DONE)
                    setSignalDataList(res.data)
                    if (res.data.signals.length === 0) {
                        toast.warn(
                            formattedToastMessage(
                                'Graph issue',
                                "We couldn't create a graph using the currently selected signals, there are no data points available."
                            ),
                            { autoClose: 20_000 }
                        )
                    }
                })
                .catch(onError)
        } else {
            setComponentState(ComponentState.DONE)
        }
    }

    const constructSignalNameKeyFromSignalEntry = (signalEntry: SignalEntry) => {
        return constructSignalNameKey(signalEntry.name, signalEntry.frameName, signalEntry.namespace)
    }

    const constructSignalNameKey = (signalName: string, frameName: string, namespace: string) => {
        const name = `${namespace}-${frameName}-${signalName}`.toLowerCase()
        return name
    }

    const hexColorFromHighchartsColor = (
        color: undefined | string | Highcharts.GradientColorObject | Highcharts.PatternObject
    ) => {
        if (color === undefined) {
            return '#dde1e6' // Appears hidden on disabled element
        }
        return color.toString()
    }

    const currentlySelectedSignals = () => {
        return selectedSignals.map((signal) => {
            const chartColor = signalColorsInChart.current.get(constructSignalNameKeyFromSignalEntry(signal))
            const isSignalInGraph = chartColor !== undefined
            const isSignalHidden =
                props.hiddenSignals.find(
                    (it) => constructSignalNameKeyFromSignalEntry(it) === constructSignalNameKeyFromSignalEntry(signal)
                ) === undefined
            return (
                <div key={constructSignalNameKeyFromSignalEntry(signal)} className="m-1">
                    <SignalInChartLabel
                        signal={signal}
                        isSignalHidden={isSignalHidden}
                        isSignalInGraph={isSignalInGraph}
                        hiddenSignals={hiddenSignals}
                        constructSignalNameKeyFromSignalEntry={constructSignalNameKeyFromSignalEntry}
                        setHiddenSignals={setHiddenSignals}
                        htmlColor={hexColorFromHighchartsColor(chartColor)}
                    />
                </div>
            )
        })
    }

    const selectSignalsButton = (size: 'sm' | 'lg') => {
        const isSmall = size === 'sm'
        return (
            <div className="d-flex flex-column align-items-center">
                <button
                    disabled={componentState === ComponentState.LOADING}
                    onClick={() => setShowSelectSignalsModal(true)}
                    className={`btn remotive-btn-primary ${isSmall ? 'remotive-btn-sm' : 'remotive-btn'}`}
                >
                    <div className="d-flex align-items-center mx-1" title="Select signals to visualize in this panel">
                        {showSelectSignalsModal ? (
                            <>
                                <Spinner size="sm" />
                            </>
                        ) : (
                            <>
                                <RuleRounded sx={{ fontSize: isSmall ? 17 : 24 }} className="me-2" />
                                <p className="m-0">Select signals</p>
                            </>
                        )}
                    </div>
                </button>
                <>
                    {!isSmall && (
                        <p className="m-0 mt-1 remotive-font-sm text-secondary">
                            Select some signals to visualize a time series graph
                        </p>
                    )}
                </>
            </div>
        )
    }

    const exportSignalsButton = (size: 'sm' | 'lg') => {
        const isSmall = size === 'sm'
        return (
            <div className="d-flex flex-column align-items-center">
                <button
                    disabled={componentState === ComponentState.LOADING}
                    onClick={() => exportSignalData()}
                    className={`btn remotive-btn-primary ${isSmall ? 'remotive-btn-sm' : 'remotive-btn'}`}
                >
                    <div className="d-flex align-items-center mx-1" title="Select signals to visualize in this panel">
                        {showSelectSignalsModal ? (
                            <>
                                <Spinner size="sm" />
                            </>
                        ) : (
                            <>
                                <RuleRounded sx={{ fontSize: isSmall ? 17 : 24 }} className="me-2" />
                                <p className="m-0">Export</p>
                            </>
                        )}
                    </div>
                </button>
                <>
                    {!isSmall && (
                        <p className="m-0 mt-1 remotive-font-sm text-secondary">
                            Select some signals to visualize a time series graph
                        </p>
                    )}
                </>
            </div>
        )
    }

    return (
        <>
            {selectableSignals && selectableSignals.length > 0 && (
                <div className="p-2 h-100 w-100">
                    <>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                                <div className="me-4">
                                    <p className="remotive-font-md lh-sm remotive-primary-60-color m-0">
                                        Signal Time Series
                                    </p>
                                </div>
                                <div>{selectedSignals.length > 0 && selectSignalsButton('sm')}</div>
                                <div>
                                    {isLocalOrDevEnvironment() &&
                                        selectedSignals.length > 0 &&
                                        exportSignalsButton('sm')}
                                </div>
                                <div className="justify-content-end p-2">
                                    <Form.Check // prettier-ignore
                                        className={'remotive-font-sm'}
                                        type="switch"
                                        checked={showYAxis}
                                        id="custom-switch"
                                        disabled={
                                            componentState === ComponentState.LOADING || selectedSignals.length < 1
                                        }
                                        label="Show y-axis"
                                        onChange={(e: any) => setShowYAxis(e.target.checked)}
                                    />
                                </div>
                            </div>
                            <div>
                                <button
                                    onClick={() => props.removeThisPanelFunction()}
                                    className="btn m-0 p-0 remotive-btn-no-bg remotive-btn-sm"
                                >
                                    <div className="d-flex align-items-center" title="Close entire panel">
                                        <CloseRounded sx={{ fontSize: 24 }} />
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div className="d-flex justify-content-start flex-row flex-wrap">
                            {currentlySelectedSignals()}
                        </div>
                    </>

                    {componentState === ComponentState.LOADING && <LoadingContainer spinnerSize="sm" />}
                    {componentState === ComponentState.ERROR && (
                        <ErrorContainer
                            errorSubText={'We encountered a problem while querying signal data.'}
                            errorText={'Signal data error'}
                        />
                    )}
                    {componentState === ComponentState.DONE && (
                        <div className="w-100 h-75">
                            {selectedSignals.length <= 0 && (
                                <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                                    {selectSignalsButton('lg')}
                                </div>
                            )}
                            <div style={{ maxHeight: '100%' }} id={props.panelKey.key}></div>
                        </div>
                    )}
                </div>
            )}

            <SelectSignalsModal
                show={showSelectSignalsModal}
                selectableSignalsWithParentFrame={selectableSignals}
                selectedSignals={selectedSignals}
                handleCloseFunction={() => setShowSelectSignalsModal(false)}
                selectSignalsFunction={setSelectedSignals}
            />
        </>
    )
}

enum ComponentState {
    LOADING,
    DONE,
    ERROR,
    UPLOADING,
    DELETING,
}
