import { useEffect } from 'react'
import { useState } from 'react'
import { Formik } from 'formik'
import { object, string } from 'yup'
import { Modal, Form, InputGroup, Row, Button, Dropdown } from 'react-bootstrap'
import { Project, RecordingFile, RecordingSession, SignalDatabase } from '../../api/CloudApi/types'
import CloudApi from '../../api/CloudApi'
import { toast } from 'react-toastify'
import LoadingContainer from '../../components/LoadingContainer'
import { DropdownIcon, WarningIcon } from '../../assets/Icons'
import InfoBox from '../../components/messageBox/InfoBox'
import { formattedToastMessage } from '../../utils/toast'
import { deletePlaybackBrokerUrl } from '../../utils/broker'

interface EditRecordingConfigurationModalProps {
    show: boolean
    handleCloseFunction: () => void
    currentRecordingFile: RecordingFile | undefined
    currentRecordingSession: RecordingSession | undefined
    availableSignalDatabases: Array<SignalDatabase>
    currentProject: Project | undefined
    refreshRecordingSession: () => void
}

const MODAL_THEME_BACKGROUND = 'remotive-primary-70-background'
const MODAL_THEME_COLOR = 'text-light'

enum ComponentState {
    LOADING,
    DONE,
}

export default function EditRecordingConfigurationModal(props: EditRecordingConfigurationModalProps) {
    const [componentState, setComponentState] = useState<ComponentState>(ComponentState.DONE)
    const [currentFileName, setCurrentFileName] = useState<string>()
    const [currentNamespace, setCurrentNamespace] = useState<string>()
    const [currentSignalDatabase, setCurrentSignalDatabase] = useState<string>()

    useEffect(() => {
        setCurrentFileName(props.currentRecordingFile?.fileName)
        setCurrentNamespace(props.currentRecordingFile?.metadata?.namespace)
        setCurrentSignalDatabase(props.currentRecordingFile?.metadata?.database)
    }, [props.currentRecordingFile])

    const closeModal = async () => {
        props.handleCloseFunction()
        await new Promise((r) => setTimeout(r, 300))
        setComponentState(ComponentState.DONE)
    }

    const saveAndClose = () => {
        const awaitableRequest = async () => {
            if (
                props.currentRecordingFile &&
                props.currentRecordingSession &&
                props.currentProject &&
                currentNamespace &&
                currentSignalDatabase
            ) {
                setComponentState(ComponentState.LOADING)
                try {
                    await CloudApi.updateRecordingFileConfiguration(
                        props.currentProject.uid,
                        props.currentRecordingSession.sessionId,
                        props.currentRecordingFile.fileName,
                        currentNamespace,
                        currentSignalDatabase
                    )
                    await props.refreshRecordingSession()
                    closeModal()
                    toast.success(
                        formattedToastMessage(
                            'Success!',
                            `The recording file configuration for ${currentFileName} has been updated`
                        )
                    )
                    deletePlaybackBrokerUrl()
                } catch (e: any) {
                    toast.error(formattedToastMessage('Failed to save', e.response?.statusText ?? 'Unknown error'))
                }
            } else {
                toast.error(
                    formattedToastMessage(
                        'Failed to save',
                        'Something went wrong when trying to save the recording file configuration. Please refresh the application and try again.'
                    )
                )
            }
        }

        awaitableRequest()
    }

    const getForm = () => {
        const schema = object().shape({
            namespace: string()
                .required('A namespace is required')
                .max(50, 'The namespace is too long!')
                .min(2, 'The namespace should be at least 2 characters long'),
        })
        const noSignalDatabasesAvailable = props.availableSignalDatabases.length === 0
        return (
            <Formik
                // After an error this is used to fake that these values has already been "touched"
                initialTouched={{
                    namespace: currentNamespace !== undefined,
                }}
                validationSchema={schema}
                initialValues={{
                    namespace: currentNamespace,
                }}
                onSubmit={() => console.log('HEJ')}
            >
                {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <div className={'mb-5'}>
                            <InfoBox
                                message={
                                    <>
                                        You are editing the file <b>{currentFileName}</b> in the recording session{' '}
                                        {props.currentRecordingSession?.displayName ?? 'N/A'}
                                    </>
                                }
                            />
                            <div>
                                <div className="d-flex flex-column w-100">
                                    <p className={`remotive-font-sm m-0 ms-1`}>Signal database</p>
                                    <p className={`remotive-font-xs text-secondary m-0 ms-1`}>
                                        The signal database is used to decode raw signal data.
                                    </p>

                                    <Dropdown className="rounded-0 w-100">
                                        <Dropdown.Toggle
                                            disabled={noSignalDatabasesAvailable}
                                            className="text-start bg-transparent border-1 remotive-primary-30-border p-0 text-truncate d-flex justify-content-between  w-100"
                                        >
                                            <div className="d-flex flex-row justify-content-between w-100">
                                                <p className="text-truncate text-dark m-0 ms-2 my-1 remotive-font-md">
                                                    {noSignalDatabasesAvailable ? (
                                                        <>
                                                            <WarningIcon
                                                                className="mb-1 text-warning me-1"
                                                                sx={{ fontSize: 17 }}
                                                            />
                                                            There are no signal databases in this project
                                                        </>
                                                    ) : (
                                                        currentSignalDatabase || 'Select file...'
                                                    )}
                                                </p>
                                                <DropdownIcon className="text-dark" sx={{ fontSize: 20 }} />
                                            </div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu
                                            style={{ minWidth: 100 }}
                                            className={`w-auto remotive-font-md p-1 px-0 position-fixed remotive-dropdown-dark`}
                                        >
                                            {props.availableSignalDatabases.map((it) => {
                                                return (
                                                    <Dropdown.Item
                                                        as={Button}
                                                        className="text-light remotive-font-md w-100"
                                                        onClick={() => setCurrentSignalDatabase(it.name)}
                                                        key={it.name}
                                                    >
                                                        {it.name}
                                                    </Dropdown.Item>
                                                )
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            <Form.Group as={Row} className="my-2">
                                <InputGroup size="sm" hasValidation={true}>
                                    <div className="d-flex flex-column w-100">
                                        <p className={`remotive-font-sm m-0 ms-1`}>Namespace</p>
                                        <p className={`remotive-font-xs text-secondary m-0 ms-1`}>
                                            The namespace is the relation between the signals in this file and the
                                            signal database file.
                                        </p>
                                        <Form.Control
                                            placeholder={'Namespace...'}
                                            type="text"
                                            className="remotive-font-md rounded border-1 remotive-primary-30-border"
                                            onBlur={handleBlur}
                                            name={'namespace'}
                                            value={currentNamespace}
                                            onChange={(it: any) => {
                                                setCurrentNamespace(it.target.value)
                                                handleChange(it)
                                            }}
                                            isValid={(!errors.namespace as boolean) && (touched.namespace as boolean)}
                                            isInvalid={errors.namespace !== undefined}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <p className="remotive-font-xs text-secondary m-0 p-1">
                                                <>{errors.namespace}</>
                                            </p>
                                        </Form.Control.Feedback>
                                    </div>
                                </InputGroup>
                            </Form.Group>
                        </div>
                        <div className="d-flex justify-content-center">
                            <Button
                                className="btn remotive-btn remotive-btn remotive-btn-primary mx-3"
                                onClick={props.handleCloseFunction}
                            >
                                <div className="d-flex align-items-center text-light">
                                    <p className="text-light m-0">Cancel</p>
                                </div>
                            </Button>
                            <Button
                                className="btn remotive-btn remotive-btn remotive-btn-success mx-3"
                                disabled={currentSignalDatabase === null || values.namespace === '' || !isValid}
                                onClick={() => saveAndClose()}
                            >
                                <div className="d-flex align-items-center text-light">
                                    <p className="text-light m-0">Save</p>
                                </div>
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        )
    }

    const getModalContent = () => {
        const title = 'Edit recording file'
        switch (componentState) {
            case ComponentState.LOADING:
                return (
                    <>
                        <Modal.Header className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR}`}>
                            <Modal.Title className="lexend-regular">{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <LoadingContainer loadingText="Updating recording file configuration..." spinnerSize='sm' />
                        </Modal.Body>
                    </>
                )

            default:
                return (
                    <>
                        <Modal.Header
                            closeButton
                            closeVariant="white"
                            className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR} lexend-regular`}
                        >
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">{getForm()}</Modal.Body>
                    </>
                )
        }
    }

    return (
        <>
            <Modal show={props.show && props.currentRecordingFile !== undefined} onHide={() => closeModal()}>
                {getModalContent()}
            </Modal>
        </>
    )
}
