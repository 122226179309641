import GoogleWorldMap from './GoogleWorldMap'
import { useEffect, useState } from 'react'
import { CheckRounded, CloseRounded } from '@mui/icons-material'
import { FrameEntry, Project, SignalEntry, SignalIdentifier } from '../../../../api/CloudApi/types'
import CloudApi from '../../../../api/CloudApi'
import { toast } from 'react-toastify'
import { LatLonCoordinate } from '../../../../types/LatLonCoordinate'
import { ANALYTICS_CHART_TYPE_KEY, AnalyticsPanelType, MapPanel, PanelKey } from '../Types'
import { PageState } from '../../../../types/PageState'
import LoadingContainer from '../../../../components/LoadingContainer'
import ErrorContainer from '../../../../components/ErrorContainer'
import SelectSignalsModal from '../../SelectSignalsModal'
import { formattedToastMessage } from '../../../../utils/toast'

interface MapPanelCardProps {
    updatePanel: (panel: MapPanel) => void
    removeThisPanelFunction: () => void
    currentProject: Project | undefined
    recordingSessionId: string | undefined
    panelKey: PanelKey
    availableFrameEntries: Array<FrameEntry>
    latitudeSignal?: SignalEntry
    longitudeSignal?: SignalEntry
}

function MapContainer(props: MapPanelCardProps) {
    const [showSetLatModal, setShowSetLatModal] = useState<boolean>(false)
    const [showSetLonModal, setShowSetLonModal] = useState<boolean>(false)
    const [longitudeSignal, setLongitudeSignal] = useState<SignalEntry | undefined>(props.longitudeSignal)
    const [latitudeSignal, setLatitudeSignal] = useState<SignalEntry | undefined>(props.latitudeSignal)
    const [coordinates, setCoordinates] = useState<Array<LatLonCoordinate>>()
    const [pageState, setPageState] = useState(PageState.DONE)

    useEffect(() => {
        if (props.recordingSessionId && (latitudeSignal !== undefined || longitudeSignal !== undefined)) {
            const panelToStore = { panelKey: props.panelKey, latitudeSignal, longitudeSignal } as MapPanel
            props.updatePanel(panelToStore)
        }
    }, [latitudeSignal, longitudeSignal])

    useEffect(() => {
        if (latitudeSignal !== undefined && longitudeSignal !== undefined) {
            fetchAndSetCoordinates()
        }
    }, [latitudeSignal, longitudeSignal])

    const fetchAndSetCoordinates = () => {
        const signalIdentifiers: Array<SignalIdentifier> = [latitudeSignal, longitudeSignal].map((it) => ({
            namespace: it!.namespace,
            frameName: it!.frameName,
            signalName: it!.name,
        }))

        const longitudeSignalString = `${longitudeSignal?.frameName}.${longitudeSignal?.name}`
        const latitudeSignalString = `${latitudeSignal?.frameName}.${latitudeSignal?.name}`
        CloudApi.getSignalsTimeseries(props.currentProject!.uid, props.recordingSessionId!, signalIdentifiers)
            .then((res) => {
                console.log(`Got res ${res.data}`)
                setPageState(PageState.DONE)
                //setSignalList(res.data)
                if (res.data.signals.length === 0) {
                    toast.warning('No signals found matching your query')
                }
                const longitudeValues =
                    res.data.signals.find((it) => it.name === longitudeSignalString)?.signals.map((it) => it[1]) ?? []
                console.log(`Longitude sigs ${longitudeValues}`)
                const latitudeValues =
                    res.data.signals.find((it) => it.name === latitudeSignalString)?.signals.map((it) => it[1]) ?? []
                console.log(`Latitude sigs ${latitudeValues}`)
                const coordinates = []
                for (let index = 0; index < Math.min(latitudeValues?.length, longitudeValues?.length); index++) {
                    const latLonCoordinate = {
                        latitude: latitudeValues[index],
                        longitude: longitudeValues[index],
                    } as LatLonCoordinate
                    coordinates.push(latLonCoordinate)
                }
                console.log(
                    `Coordinates ${coordinates.map((it) => {
                        return `lat=${it.latitude} lng=${it.longitude}`
                    })}`
                )
                setCoordinates(coordinates)
            })
            .catch((e: any) => {
                console.log(`LatLon error: ${e}`)
            })
    }

    const chartTypeAttribute = { [ANALYTICS_CHART_TYPE_KEY]: AnalyticsPanelType.MAP_WITH_ROUTE }

    return (
        <>
            <>
                <div className="d-flex justify-content-between px-2 pt-2 pb-1" {...chartTypeAttribute}>
                    <div>
                        <p className="remotive-font-md lh-sm remotive-primary-60-color m-0">Driving Route</p>
                        <p className="remotive-font-xs lh-1 remotive-primary-40-color text-secondary m-0">
                            Use latitude and longitude signals to plot the driving route
                        </p>
                    </div>
                    <div>
                        <button
                            onClick={() => props.removeThisPanelFunction()}
                            className="btn m-0 p-0 remotive-btn-no-bg remotive-btn-sm"
                        >
                            <div className="d-flex align-items-center" title="Close entire panel">
                                <CloseRounded sx={{ fontSize: 24 }} />
                            </div>
                        </button>
                    </div>
                </div>
                <div className="w-100 h-100 mb-2">
                    {pageState === PageState.LOADING && <LoadingContainer spinnerSize="sm" />}
                    {pageState === PageState.ERROR && (
                        <ErrorContainer
                            errorSubText={'We encountered a problem while querying signal data.'}
                            errorText={'Signal data error'}
                        />
                    )}
                    {latitudeSignal !== undefined && longitudeSignal !== undefined ? (
                        <GoogleWorldMap coordinates={coordinates ?? []} />
                    ) : (
                        <div className="d-flex flex-column align-items-center justify-content-center h-100">
                            <p className="m-0 remotive-font d-flex align-items-center">Set latitude and longitude</p>
                            <p className="m-0 remotive-font-sm text-secondary">
                                Both latitude and longitude must be set to visualize a map
                            </p>
                            <div className="mt-2">
                                <button
                                    className={`button remotive-btn-md remotive-btn-${
                                        longitudeSignal === undefined ? 'primary' : 'success'
                                    } border-0 me-2`}
                                    title="Set longitude"
                                    onClick={() => {
                                        setShowSetLonModal(true)
                                    }}
                                >
                                    {longitudeSignal === undefined ? (
                                        <>Set longitude</>
                                    ) : (
                                        <>
                                            <CheckRounded sx={{ fontSize: 17 }} /> Longitude is set
                                        </>
                                    )}
                                </button>

                                <button
                                    className={`btn remotive-btn-md remotive-btn-${
                                        latitudeSignal === undefined ? 'primary' : 'success'
                                    } border-0 me-2`}
                                    title="Set latitude"
                                    onClick={() => {
                                        setShowSetLatModal(true)
                                    }}
                                >
                                    {latitudeSignal === undefined ? (
                                        <>Set latitude</>
                                    ) : (
                                        <>
                                            <CheckRounded sx={{ fontSize: 17 }} /> Latitude is set
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </>

            <SelectSignalsModal
                show={showSetLatModal}
                selectableSignalsWithParentFrame={props.availableFrameEntries.filter((it) => it.cnt > 0)}
                selectedSignals={latitudeSignal !== undefined ? [latitudeSignal] : []}
                handleCloseFunction={() => setShowSetLatModal(false)}
                title="Select latitude signal"
                signalLimit={1}
                selectSignalsFunction={(signalEntries: Array<SignalEntry>) => {
                    if (signalEntries.length === 1) {
                        setLatitudeSignal(signalEntries[0])
                    } else {
                        toast.error(
                            formattedToastMessage(
                                'Selection error',
                                'You can only select one signal for the latitude value.'
                            )
                        )
                    }
                }}
            />

            <SelectSignalsModal
                show={showSetLonModal}
                selectableSignalsWithParentFrame={props.availableFrameEntries.filter((it) => it.cnt > 0)}
                selectedSignals={longitudeSignal !== undefined ? [longitudeSignal] : []}
                handleCloseFunction={() => setShowSetLonModal(false)}
                title="Select longitude signal"
                signalLimit={1}
                selectSignalsFunction={(signalEntries: Array<SignalEntry>) => {
                    if (signalEntries.length === 1) {
                        setLongitudeSignal(signalEntries[0])
                    } else {
                        toast.error(
                            formattedToastMessage(
                                'Selection error',
                                'You can only select one signal for the longitude value.'
                            )
                        )
                    }
                }}
            />
        </>
    )
}

export default MapContainer
