import { toast } from 'react-toastify'
import { Spinner } from 'react-bootstrap'
import { useState } from 'react'
import {
    ProcessingError,
    ProcessingRecordingFile,
    ProcessingRecordingFileStatus,
    ProcessingRecordingFileStep,
    Project,
    UserBillableUnitInfo,
} from '../api/CloudApi/types'
import { DeleteIcon, ErrorIcon, SuccessIcon } from '../assets/Icons'
import { Permission, hasPermission } from '../utils/permission'
import CloudApi from '../api/CloudApi'
import { ComponentState } from '../types/ComponentState'

interface ProcessingRecordingListItemProps {
    background: 'white' | 'remotive-primary-10'
    recording: ProcessingRecordingFile
    project: Project
    billableUnit: UserBillableUnitInfo
    refreshProcessingRecordingState: Function
}

export default function ProcessingRecordingListItem(props: ProcessingRecordingListItemProps) {
    const [componentState, setComponentState] = useState<ComponentState>(ComponentState.DEFAULT)

    const deleteProcessingRecording = async (recordingFile: ProcessingRecordingFile, project: Project) => {
        setComponentState(ComponentState.DELETING)
        try {
            await CloudApi.deleteProcessingRecordingFile(project.uid, recordingFile)
            props.refreshProcessingRecordingState()
        } catch (err: any) {
            toast.error('Failed to delete recording')
            setComponentState(ComponentState.DEFAULT)
        }
    }

    const getProcessingMessage = (recording: ProcessingRecordingFile) => {
        switch (recording.step) {
            case ProcessingRecordingFileStep.REQUESTED:
                return 'Preparing file...'
            case ProcessingRecordingFileStep.VALIDATING:
                return 'Validating file...'
            case ProcessingRecordingFileStep.CONVERT:
                return 'Converting file...'
            case ProcessingRecordingFileStep.SPLIT:
                return 'Splitting file...'
            case ProcessingRecordingFileStep.ZIP:
                return 'Compressing file...'
            case ProcessingRecordingFileStep.FINALIZE:
                return 'Finishing up...'
            default:
                return 'Processing...'
        }
    }

    const convertErrorCodeToMessage = (processingError: ProcessingError) => {
        switch (processingError.code) {
            case 429:
                return 'We are currently experiencing high traffic, please try again later.'

            default:
                return undefined
        }
    }

    const extractErrorMessage = (recording: ProcessingRecordingFile) => {
        try {
            if (recording.errors?.[0] === undefined) {
                throw Error('The recording failed but there was no error object, returning a default error message.')
            }
            return convertErrorCodeToMessage(recording.errors[0]) ?? recording.errors[0].message
        } catch (e: any) {
            console.error(e)
            return 'Unexpected error'
        }
    }

    const listItem = (recording: ProcessingRecordingFile, project: Project, billableUnit: UserBillableUnitInfo) => {
        const isFailed = recording.status === ProcessingRecordingFileStatus.FAILED
        const isDone = recording.status === ProcessingRecordingFileStatus.DONE
        const isRunning = recording.status === ProcessingRecordingFileStatus.RUNNING || recording.status === ProcessingRecordingFileStatus.READY
        return (
            <div
                key={recording.uploadId}
                className={`rounded ${
                    props.background === 'white' ? 'bg-white' : 'remotive-primary-10-background'
                } border-bottom m-1 mx-0 px-1 flex-truncate`}
            >
                <div className="d-flex justify-content-between mb-1 flex-truncate">
                    <div className="d-flex flex-truncate">
                        <div className="d-flex justify-content-center align-items-center mx-3">
                            {isFailed ? (
                                <ErrorIcon style={{ fontSize: 18 }} className="text-danger" />
                            ) : isDone ? (
                                <SuccessIcon style={{ fontSize: 18 }} className="text-success" />
                            ) : (
                                <Spinner size="sm" />
                            )}
                        </div>
                        <div className="p-1 d-flex flex-column w-100 text-truncate flex-truncate">
                            <p className="remotive-font-md mb-0 text-truncate">{recording.fileName}</p>
                            <p className="remotive-font-sm text-secondary mb-0 text-truncate">
                                <b>{isFailed ? extractErrorMessage(recording) : getProcessingMessage(recording)}</b>
                            </p>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end align-items-center">
                        <button
                            className={'btn remotive-btn-no-bg p-1 px-0 border-0 mx-3'}
                            disabled={
                                !hasPermission(Permission.PROJECT_EDITOR_RECORDING, billableUnit, project) || isRunning
                            }
                            onClick={() => deleteProcessingRecording(recording, project)}
                        >
                            {componentState === ComponentState.DELETING ? (
                                <>
                                    <Spinner size="sm" />
                                </>
                            ) : (
                                <DeleteIcon sx={{ fontSize: 20 }} />
                            )}
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    return listItem(props.recording, props.project, props.billableUnit)
}
