import { CrossIcon, ExternalLinkIcon, FullscreenIcon, MinimizeFullscreenIcon, ReloadIcon } from '../assets/Icons'
import { BrokerContainer } from '../api/CloudApi/types'
import CloudApi from '../api/CloudApi'
import { isDemo } from '../utils/demo'
import { useEffect, useState } from 'react'
import useWindowDimensions from '../hooks/useWindowDimensions'
import ScreenSize, { BootstrapBreakpoint } from '../utils/ScreenSize'

interface BrokerAppWrapperProps {
    isPlaybackAccordionCollapsed: boolean
    brokerContainer: BrokerContainer
    setBrokerContainer: (brokerContainer: BrokerContainer | undefined) => void
}

const FULLSCREEN_STYLE = {
    paddingTop: 0,
    minHeight: '100%',
    minWidth: '100%',
    zIndex: 9999,
    top: 0,
    left: 0,
}

const CACHE_BUSTER_PARAMETER = Date.now()

const BROKER_APP_IFRAME_TOOLBAR_HEIGHT = 45

export default function BrokerAppWrapper(props: BrokerAppWrapperProps) {
    const navigationTopNavbar = document.getElementById('navigation-top-navbar')?.getBoundingClientRect()?.height ?? 0
    const [isFullScreen, setIsFullScreen] = useState<boolean>(false)
    const { height, width } = useWindowDimensions()
    const [iframeHeight, setIframeHeight] = useState<number>()

    useEffect(() => {
        const resizeIframe = async () => {
            const recordingDetailsPlaybackAccordionCardHeight =
                (document.getElementById('recording-details-playback-accordion')?.getBoundingClientRect()?.height ??
                    0) + 4
            const recordingDetailsPageHeader =
                (document.getElementById('recording-details-page-header')?.getBoundingClientRect()?.height ?? 0) + 16
            const recordingDetailsTabs =
                (document.getElementById('recording-details-tabs')?.getBoundingClientRect()?.height ?? 0) + 8
            const recordingDetailsPlaybackButton =
                (document.getElementById('recording-details-playback-button')?.getBoundingClientRect()?.height ?? 0) +
                48
                const cliHintsAccordion =
                (document.getElementById('cli-hint-container')?.getBoundingClientRect()?.height ?? 0) + 4
            setIframeHeight(
                isFullScreen
                    ? height - BROKER_APP_IFRAME_TOOLBAR_HEIGHT
                    : height -
                          navigationTopNavbar -
                          recordingDetailsPageHeader -
                          recordingDetailsTabs -
                          recordingDetailsPlaybackAccordionCardHeight -
                          cliHintsAccordion -
                          recordingDetailsPlaybackButton +
                          (props.isPlaybackAccordionCollapsed ? 50 : 0)
            )
        }

        resizeIframe()
    }, [props.isPlaybackAccordionCollapsed, height, width, isFullScreen])

    const createBrokerAppBaseUrl = (isIframe: boolean) => {
        return CloudApi.getBrokerAppUrl(props.brokerContainer, isDemo(), undefined, isIframe)
    }

    const [brokerAppUrl, setBrokerAppUrl] = useState<string>(
        `${createBrokerAppBaseUrl(true)}&cache_buster=${CACHE_BUSTER_PARAMETER}`
    )

    useEffect(() => {
        const handleEsc = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setIsFullScreen(false)
            }
        }
        window.addEventListener('keydown', handleEsc)

        return () => {
            window.removeEventListener('keydown', handleEsc)
        }
    }, [])

    const goToUrlButton = () => {
        return (
            <>
                <div className="d-flex justify-content-center align-items-center flex-column me-2">
                    <a className="m-0 p-0" href={createBrokerAppBaseUrl(false)} target={'_blank'}>
                        <button
                            className="btn remotive-btn-primary"
                            style={{ padding: 3, borderWidth: 2, borderRadius: 100, marginBottom: 1 }}
                            title="New tab"
                        >
                            <div className="d-flex align-items-center">
                                <ExternalLinkIcon sx={{ fontSize: 11 }} />
                            </div>
                        </button>
                    </a>
                </div>
            </>
        )
    }

    const resizeButton = () => {
        return (
            <div style={{ marginTop: 1 }} className="d-flex justify-content-center align-items-center flex-column me-2">
                {isFullScreen ? (
                    <>
                        <button
                            onClick={() => setIsFullScreen(false)}
                            className="btn remotive-btn-success"
                            style={{ padding: 3, borderWidth: 2, borderRadius: 100 }}
                            title="Minimize"
                        >
                            <div className="d-flex align-items-center">
                                <MinimizeFullscreenIcon sx={{ fontSize: 11 }} />
                            </div>
                        </button>
                    </>
                ) : (
                    <>
                        <button
                            onClick={() => setIsFullScreen(true)}
                            className="btn remotive-btn-primary"
                            style={{ padding: 3, borderWidth: 2, borderRadius: 100 }}
                            title="Fullscreen"
                        >
                            <div className="d-flex align-items-center">
                                <FullscreenIcon sx={{ fontSize: 11 }} />
                            </div>
                        </button>
                    </>
                )}
            </div>
        )
    }

    const reloadButton = () => {
        return (
            <>
                <div
                    style={{ marginTop: 1 }}
                    className="d-flex justify-content-center align-items-center flex-column me-2"
                >
                    <button
                        onClick={() => setBrokerAppUrl(`${createBrokerAppBaseUrl(true)}&cache_buster=${Date.now()}`)}
                        className="btn remotive-btn-secondary"
                        style={{ padding: 3, borderWidth: 2, borderRadius: 100 }}
                        title="Reload"
                    >
                        <div className="d-flex align-items-center">
                            <ReloadIcon sx={{ fontSize: 11 }} />
                        </div>
                    </button>
                </div>
            </>
        )
    }

    const closeButton = () => {
        return (
            <>
                <div
                    style={{ marginTop: 1 }}
                    className="d-flex justify-content-center align-items-center flex-column me-2"
                >
                    <button
                        onClick={() => props.setBrokerContainer(undefined)}
                        className="btn remotive-btn-secondary"
                        style={{ padding: 2, borderWidth: 2, borderRadius: 100 }}
                        title="Close"
                    >
                        <div className="d-flex align-items-center">
                            <CrossIcon sx={{ fontSize: 11 }} />
                        </div>
                    </button>
                </div>
            </>
        )
    }

    return (
        <div
            className={`d-flex flex-column remotive-main-background bg-white rounded-4 ${
                ScreenSize.isSmallerOrEqualTo(BootstrapBreakpoint.LG) ? 'mb-5' : 'mb-0'
            }`}
            style={isFullScreen ? { ...FULLSCREEN_STYLE, position: 'absolute' } : { marginTop: 8 }}
        >
            <div
                className="d-flex justify-content-between remotive-neutral-10-background rounded-top-4 shadow-sm"
                style={{ zIndex: 10 }}
            >
                <div
                    className={`d-flex flex-row justify-content-start align-items-center py-1 ${
                        isFullScreen ? '' : 'border-2'
                    }`}
                >
                    <div className="ms-3">{resizeButton()}</div>
                    <div className="">{goToUrlButton()}</div>
                    <div className="">{reloadButton()}</div>
                </div>
                <div className="d-flex justify-content-center align-items-center me-1">{closeButton()}</div>
            </div>

            <iframe
                src={brokerAppUrl}
                width={'100%'}
                style={{
                    height: iframeHeight,
                }}
                allow="clipboard-write"
                className={`${isFullScreen ? '' : 'border-5 border-white border-start border-bottom border-end rounded-bottom-4 shadow-sm'}`}
            />
        </div>
    )
}
