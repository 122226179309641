import { Form, Modal, Spinner } from 'react-bootstrap'
import { Project, RecordingSession } from '../api/CloudApi/types'
import { useEffect, useState } from 'react'
import CloudApi from '../api/CloudApi'
import ErrorContainer from '../components/ErrorContainer'
import { CheckIcon, SuccessCheckmarkIcon, SuccessIcon, WarningIcon } from '../assets/Icons'
import { toast } from 'react-toastify'
import { formattedToastMessage } from '../utils/toast'
const MODAL_THEME_BACKGROUND = 'remotive-primary-70-background'
const MODAL_THEME_COLOR = 'text-light'

interface ImportRecordingSessionProps {
    show: boolean
    currentProject?: Project
    recordingsInProject?: Array<RecordingSession>
    onRecordingImported: () => void
    onModalClose: (imported: boolean) => void
}

enum ImportState {
    NOT_STARTED,
    LOADING,
    ERROR,
}

export interface RecordingSessionInProgress {
    recordingSession: RecordingSession
    state: ImportState
    errorText?: string
}

export default function ImportRecordingSessionModal(props: ImportRecordingSessionProps) {
    const [importState, setImportState] = useState(ImportState.LOADING)
    const [errorText, setErrorText] = useState<string>()
    const [availableSampleRecordings, setAvailableSampleRecordings] = useState<Array<RecordingSession>>()
    const [recordingsToImport, setRecordingsToImport] = useState<Array<RecordingSessionInProgress>>()
    const [selectedRecordings, setSelectedRecordings] = useState<Array<string>>([])

    const importInProgress = (): boolean => {
        const state = recordingsToImport?.filter((r) => r.state !== ImportState.LOADING)
        return state?.length !== recordingsToImport?.length
    }

    const onRecordingSelectionChanged = (sessionId: string, isChecked: boolean) => {
        const copy = [...selectedRecordings]
        const index = copy.indexOf(sessionId)
        if (index !== -1) {
            // Removes from array
            const _ = copy.splice(index, 1)
        }
        if (isChecked) {
            copy.push(sessionId)
        }
        setSelectedRecordings(copy)
    }

    const isRecordingAlreadyInProject = (recordingSession: RecordingSession) => {
        const r = props.recordingsInProject?.filter((r) => r.sessionId === recordingSession.sessionId)
        return !!(r && r.length > 0)
    }

    const isBeingImported = (recordingSession: RecordingSession) => {
        return (
            importState === ImportState.LOADING &&
            recordingsToImport?.map((it) => it.recordingSession.sessionId).includes(recordingSession.sessionId)
        )
    }

    const areAllRecordingsInProject = () => {
        const recordings = props.recordingsInProject?.map((r) => r.sessionId)
        return (
            availableSampleRecordings?.filter((r) => recordings?.includes(r.sessionId)).length ===
            availableSampleRecordings?.length
        )
    }

    const handleImportRecordings = async () => {
        if (selectedRecordings.length === 0) {
            // Should not happen
            console.log('No recordings selected')
        }
        const recordings = availableSampleRecordings?.filter((r) => selectedRecordings.includes(r.sessionId)) ?? []
        const inProgress: Array<RecordingSessionInProgress> = recordings.map((s) => ({
            recordingSession: s,
            state: ImportState.LOADING,
        }))

        setImportState(ImportState.LOADING)

        const done = async (r: RecordingSessionInProgress) => {
            await props.onRecordingImported()
            r.state = ImportState.NOT_STARTED
            setRecordingsToImport([...inProgress!])
            toast.success(
                formattedToastMessage(
                    'Import successful',
                    `Successfully imported the sample recording ${r.recordingSession.displayName}.`
                )
            )
        }

        const error = (r: RecordingSessionInProgress, e: any) => {
            r.state = ImportState.ERROR
            r.errorText = e.message
            setRecordingsToImport([...inProgress!])
            toast.error(
                formattedToastMessage(
                    'Import failed',
                    `Failed to import the sample recording ${r.recordingSession.displayName}, please try again.`
                )
            )
        }

        setRecordingsToImport(inProgress)

        const promises = inProgress!.map((r) =>
            CloudApi.copySampleRecordingSession(props.currentProject!.uid, r.recordingSession.sessionId)
                .then(() => done(r))
                .catch((e: any) => error(r, e))
        )
        //const results = await Promise.all(promises)
    }

    useEffect(() => {
        const fetchRecordings = async () => {
            try {
                setAvailableSampleRecordings((await CloudApi.listSampleRecordingSessions()).data)
                setImportState(ImportState.NOT_STARTED)
            } catch (e: any) {
                setImportState(ImportState.ERROR)
            }
        }

        fetchRecordings()
    }, [])

    useEffect(() => {
        if (!importInProgress()) {
            close(true)
        }
    }, [recordingsToImport])

    const doClose = (imported: boolean) => {
        props.onModalClose(false)
    }

    const close = (imported: boolean) => {
        setSelectedRecordings([])
        setRecordingsToImport(undefined)
        setErrorText(undefined)
        setImportState(ImportState.NOT_STARTED)
    }

    const recordingItemBeingImported = (recordingSession: RecordingSession) => {
        return (
            <div key={recordingSession.sessionId} className={'d-flex align-items-center'}>
                <Spinner className={`remotive-primary-50-color`} size="sm" />
                <p className={'p-0 mb-0'} style={{ marginLeft: 10 }}>
                    Importing <b>{recordingSession.displayName}...</b>
                </p>
            </div>
        )
    }

    const recordingItemAlreadyImported = (recordingSession: RecordingSession) => {
        return (
            <div className="d-flex align-items-center">
                <SuccessCheckmarkIcon sx={{ fontSize: 20 }} className="remotive-success-60-color" />
                <p className="mb-0" style={{ marginLeft: 6 }}>
                    {recordingSession.displayName}
                </p>
            </div>
        )
    }

    const recordingItemReadyToImport = (recordingSession: RecordingSession) => {
        return (
            <Form.Check
                style={{ marginLeft: 2 }}
                disabled={isRecordingAlreadyInProject(recordingSession)}
                type="checkbox"
                id={recordingSession.sessionId}
                key={recordingSession.sessionId}
                value={recordingSession.sessionId}
                label={`${recordingSession.displayName}`}
                onChange={(e) => onRecordingSelectionChanged(e.target.value, e.target.checked)}
            />
        )
    }

    const sampleRecordingsList = () => {
        return availableSampleRecordings?.map((it) => {
            if (isRecordingAlreadyInProject(it)) {
                return recordingItemAlreadyImported(it)
            }
            if (isBeingImported(it)) {
                return recordingItemBeingImported(it)
            }
            return recordingItemReadyToImport(it)
        })
    }

    const getBody = () => {
        return (
            <>
                <Modal.Header
                    className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR} lexend-regular`}
                    closeButton
                    closeVariant="white"
                >
                    <Modal.Title>Import sample recordings</Modal.Title>
                </Modal.Header>
                <Modal.Body className="lexend-regular mx-2">
                    <div className="h-100 mt-3 mb-3">
                        <div className="mb-3">
                            {areAllRecordingsInProject() ? (
                                <div className="d-flex flex-column align-items-center">
                                    <SuccessIcon sx={{ fontSize: 40 }} className="remotive-success-50-color mb-2" />
                                    <p>All sample recordings have been imported!</p>
                                </div>
                            ) : (
                                <>
                                    <p className="m-0 remotive-font">
                                        Select the sample recordings below that you want to import into this project.
                                    </p>
                                    <p className="m-0 text-secondary remotive-font-sm">
                                        These sample recordings include a large set of recorded CAN signal data as well
                                        as VSS and AAOS signal tranformations.
                                    </p>
                                </>
                            )}
                        </div>
                        <div key={`remotive-recording`} className="mb-3">
                            <Form>{sampleRecordingsList()}</Form>
                        </div>
                    </div>
                    {importState === ImportState.ERROR && (
                        <ErrorContainer errorText="Could not copy recording" errorSubText={errorText} />
                    )}
                </Modal.Body>
                <div className="my-1 lexend-regular">
                    <div className={'d-flex flex-row justify-content-center'}>
                        <div className="me-4">
                            <button
                                className="btn remotive-btn remotive-btn-primary flex-grow-0"
                                disabled={importState === ImportState.LOADING}
                                onClick={() => doClose(true)}
                            >
                                <p className="m-0">Close</p>
                            </button>
                        </div>
                        <div className="d-flex flex-column align-items-center mb-3" style={{ minWidth: 106 }}>
                            <button
                                className="btn remotive-btn remotive-btn-success flex-grow-0"
                                onClick={handleImportRecordings}
                                disabled={importState === ImportState.LOADING || selectedRecordings.length === 0}
                            >
                                <p className="m-0">Import</p>
                            </button>
                            {selectedRecordings.length === 0 && !areAllRecordingsInProject() && (
                                <div className="d-flex flex-row justify-content-center align-items-center mt-1">
                                    <WarningIcon className="text-warning" sx={{ fontSize: 13 }} />
                                    <p className="text-secondary remotive-font-xs m-0 ms-1 ">Select a recording</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        )
        // case ImportState.LOADING:
        //     return <LoadingContainer loadingText={'Importing recordings...'} infoText={'This may take a while'} />
    }

    return (
        <>
            <Modal show={props.show} onHide={() => doClose(true)}>
                {getBody()}
            </Modal>
        </>
    )
}
