import { useEffect } from 'react'
import { useState } from 'react'
import { Modal, Form, InputGroup, Spinner, Row, Button, Col, Accordion } from 'react-bootstrap'
import CloudApi from '../../api/CloudApi'
import { Project } from '../../api/CloudApi/types'
import { ErrorIcon, PlayIcon, WarningIcon } from '../../assets/Icons'
import BrokerDetails from '../../pages/BrokerDetails'
import { Formik } from 'formik'
import { object, string } from 'yup'
import { isDemo } from '../../utils/demo'
import { CLOUD_RESOURCE_VALIDATION_REGEX_PATTERN } from '../../validation/CloudValidation'
import { isLocalOrDevEnvironment } from '../../utils/environment'

interface CreateBrokerModalProps {
    currentProject: Project | undefined
    show: boolean
    handleCloseFunction: () => void
    reloadBrokersList: () => void
}

const MODAL_THEME_BACKGROUND = 'remotive-primary-70-background'
const MODAL_THEME_COLOR = 'text-light'

export default function CreateBrokerModal(props: CreateBrokerModalProps) {
    const [brokerState, setBrokerState] = useState<'NOT_STARTED' | 'IN_PROGRESS' | 'DONE' | 'ERROR'>('NOT_STARTED')
    const [backendErrorMessage, setBackendErrorMessage] = useState<string>()

    useEffect(() => {
        console.debug('Mounted create broker modal!')
    }, [])

    useEffect(() => {
        if (brokerState === 'DONE') {
            closeModal()
            props.reloadBrokersList()
        } else if (brokerState === 'ERROR') {
            props.reloadBrokersList()
        }
    }, [brokerState])

    const resetState = () => {
        setBrokerState('NOT_STARTED')
    }

    const createBroker = async (brokerName: string, brokerApiKey: string, brokerTag: string, lifecycle: string) => {
        if (props.currentProject) {
            setBrokerState('IN_PROGRESS')
            try {
                setBackendErrorMessage(undefined)
                console.log(`${brokerName} ${brokerApiKey} ${brokerTag}`)
                await CloudApi.createBroker(props.currentProject.uid, brokerName, brokerApiKey, brokerTag, lifecycle)
                setBrokerState('DONE')
            } catch (err: any) {
                setBrokerState('ERROR')
                setBackendErrorMessage(err.response.data)
            }
        }
    }

    const closeModal = () => {
        props.handleCloseFunction()
        resetState()
    }

    const getModalContent = () => {
        const schema = object().shape({
            name: string()
                .required()
                .matches(new RegExp(CLOUD_RESOURCE_VALIDATION_REGEX_PATTERN), 'Not a valid broker name'),

            apiKey: string().optional().min(3),
            tag: string().optional(),
        })

        const title = 'Create new broker'
        switch (brokerState) {
            case 'IN_PROGRESS':
                return (
                    <>
                        <Modal.Header className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR}`}>
                            <Modal.Title className="lexend-regular">{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <div className="text-center h-100 mt-3 mb-3">
                                <p className="fs-5 m-1">Creating broker...</p>
                                <Spinner className="remotive-primary-50-color" size="sm" />
                            </div>
                        </Modal.Body>
                    </>
                )
            case 'ERROR':
                return (
                    <>
                        <Modal.Header
                            closeButton
                            closeVariant="white"
                            className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR}`}
                        >
                            <Modal.Title className="lexend-regular">{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <div className="mb-3 mt-3 text-center">
                                <ErrorIcon sx={{ fontSize: 35 }} className="me-1 remotive-warning-100-color" />
                                <p className="fs-5 m-0">There was an issue creating your broker</p>

                                <p className="remotive-font-sm text-secondary" style={{ marginTop: '-5px' }}>
                                    {backendErrorMessage}
                                </p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button className="btn remotive-btn remotive-btn-primary" onClick={() => closeModal()}>
                                    Close
                                </button>
                            </div>
                        </Modal.Body>
                    </>
                )

            case 'DONE':
                return (
                    <>
                        <Modal.Header className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR}`}>
                            <Modal.Title className="lexend-regular">{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <div className="text-center h-100 mt-3 mb-3">
                                <p className="fs-5 m-1">Done!</p>
                            </div>
                        </Modal.Body>
                    </>
                )

            default:
                return (
                    <>
                        <Modal.Header
                            closeButton
                            closeVariant="white"
                            className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR} lexend-regular`}
                        >
                            <Modal.Title className="lexend-regular">{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <Formik
                                validationSchema={schema}
                                onSubmit={(event: any) => {
                                    createBroker(event.name, event.apiKey, event.tag, event.lifecycle)
                                }}
                                isInitialValid={false}
                                initialValues={{
                                    name: '',
                                    apiKey: '',
                                    tag: '',
                                    lifecycle: 'EPHEMERAL',
                                }}
                            >
                                {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Row className={'mb-3'}>
                                            <Form.Group as={Row} controlId={'nameValidationId'}>
                                                <InputGroup size="sm" hasValidation={true}>
                                                    <InputGroup.Text
                                                        className={`remotive-font-md bg-transparent border-0 w-25`}
                                                    >
                                                        Name
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                        className="remotive-font-sm w-75"
                                                        type="text"
                                                        placeholder="E.g my-car-signal-bus"
                                                        name={'name'}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.name}
                                                        isInvalid={errors.name !== undefined}
                                                    />
                                                    <div className="w-25"></div>
                                                    <p className="remotive-font-xs text-secondary m-0 p-1 w-75">
                                                        Only lowercase, digits, and hyphens. Must begin with letter, and
                                                        may not end with hyphen.
                                                    </p>
                                                    <div className="w-25"></div>
                                                    <Form.Control.Feedback className={'w-75'} type="invalid">
                                                        <>{errors.name}</>
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                        </Row>
                                        {/* Optional items a collapsed by default */}
                                        <Accordion defaultActiveKey={undefined} className="ms-2 mb-3">
                                            <Accordion.Item eventKey="0" className="border-0">
                                                <Accordion.Header>
                                                    <div className="d-flex flex-column">
                                                        <p className="m-0 p-0 remotive-font-md">Optional</p>
                                                        <p className="m-0 p-0 remotive-font-xs text-secondary">
                                                            API-key and specific broker version.
                                                        </p>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <Row className="mb-2">
                                                        <InputGroup className="" size="sm" hasValidation={true}>
                                                            <InputGroup.Text
                                                                className={`remotive-font-md bg-transparent border-0 w-25`}
                                                            >
                                                                API-key
                                                            </InputGroup.Text>
                                                            <Form.Control
                                                                className="remotive-font-sm w-75"
                                                                type="text"
                                                                name={'apiKey'}
                                                                onChange={handleChange}
                                                                value={values.apiKey}
                                                            />
                                                            <div className="w-25"></div>
                                                            <p className="remotive-font-xs text-secondary m-0 w-75">
                                                                Will be automatically generated if left empty
                                                                (recommended)
                                                            </p>
                                                            <Form.Control.Feedback type="invalid">
                                                                <>{errors.apiKey}</>
                                                            </Form.Control.Feedback>
                                                        </InputGroup>
                                                    </Row>

                                                    <div className="mb-2">
                                                        <InputGroup size="sm">
                                                            <InputGroup.Text
                                                                className={`remotive-font-md bg-transparent border-0 w-25`}
                                                            >
                                                                Version
                                                            </InputGroup.Text>
                                                            <Form.Control
                                                                className="remotive-font-sm w-75"
                                                                type="text"
                                                                name={'tag'}
                                                                onChange={handleChange}
                                                            />
                                                            <div className="w-25"></div>
                                                            <p className="remotive-font-xs text-secondary m-0 w-75">
                                                                Only use a version/tag if you got this sent from us at
                                                                RemotiveLabs
                                                            </p>
                                                        </InputGroup>
                                                    </div>

                                                    <div>
                                                        <p className={'remotive-font-sm p-2 border-top border-bottom'}>
                                                            <WarningIcon
                                                                className="text-warning align-text-bottom"
                                                                sx={{ fontSize: 16 }}
                                                            />{' '}
                                                            API-key is deprecated in favour of personal access-tokens.
                                                            Support for API-keys be removed in future releases. Read
                                                            more -{' '}
                                                            <a
                                                                href={
                                                                    'https://docs.remotivelabs.com/docs/cloud/guides/migrate-from-apikeys'
                                                                }
                                                                target={'_blank'}
                                                            >
                                                                how-to migrate from api-keys.
                                                            </a>
                                                        </p>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                        <div className="d-flex justify-content-end">
                                            <Button
                                                className="btn remotive-btn remotive-btn-success align-center"
                                                disabled={!isValid}
                                                type={'submit'}
                                            >
                                                <div className="d-flex align-items-center text-light">
                                                    <p className="text-light m-0">Create</p>
                                                </div>
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </Modal.Body>
                    </>
                )
        }
    }

    return (
        <>
            <Modal bsPrefix="" show={props.show} onHide={() => closeModal()}>
                {getModalContent()}
            </Modal>
        </>
    )
}
