import Dropzone, { DropzoneRef, DropzoneRootProps, DropzoneInputProps } from 'react-dropzone'
import { useState, forwardRef } from 'react'
import { toast } from 'react-toastify'
import { AxiosProgressEvent, AxiosRequestConfig } from 'axios'

import { BillableUnit, Project, RecordingSession, UserBillableUnitInfo } from '../api/CloudApi/types'
import { CloudUploadIcon } from '../assets/Icons'
import { ComponentState } from '../types/ComponentState'
import { formattedToastMessage } from '../utils/toast'
import CloudApi from '../api/CloudApi'
import FileUploadProgressContainer from './FileUploadProgressContainer'
import InlineFileUploadProgressContainer from './InlineFileUploadProgressContainer'
import {
    AnalyticsContext,
    AnalyticsProperties,
    createAnalyticsTrackingKey,
    ProductAnalyticsProps,
    useProductAnalyticsClient,
} from '../utils/ProductAnalytics'

interface RecordingDropzoneProps {
    project: Project
    type: 'recordingFile' | 'recordingSession'
    analyticsProperties: AnalyticsProperties
    parentRecordingSession?: RecordingSession
    hasPermissionToUpload?: boolean
    helpTextElement?: JSX.Element
    highlightHelpText?: Function
    onUploadComplete?: Function
}

export const ALLOWED_RECORDING_FILE_NAME_ENDINGS = ['asc', 'blf', 'log', 'mf4', 'txt', 'zip']

export default forwardRef(function RecordingDropzone(
    props: RecordingDropzoneProps,
    refFromParent: React.Ref<DropzoneRef>
) {
    const [componentState, setComponentState] = useState<ComponentState>(ComponentState.DEFAULT)
    const [uploadPercent, setUploadPercent] = useState<number>(0)
    const [isHoveringDropzone, setIssHoveringDropzone] = useState<boolean>(false)

    const productAnalyticsClient = useProductAnalyticsClient({
        user: props.analyticsProperties?.currentUser,
        billableUnit: props.analyticsProperties?.currentBillableUnit,
    } as ProductAnalyticsProps)

    const AnalyticsActions = {
        UPLOAD_RECORDING: createAnalyticsTrackingKey(
            props.analyticsProperties?.analyticsContext ?? AnalyticsContext.NOT_SET,
            'UploadRecording'
        ),
    }

    const isUnsupportedFilename = (filename: string) => {
        const dotSplitFilename = filename.split('.')
        // We support files without a file ending but also everything in the list ALLOWED_FILE_NAME_ENDINGS
        return (
            dotSplitFilename.length > 1 &&
            !ALLOWED_RECORDING_FILE_NAME_ENDINGS.includes(dotSplitFilename[dotSplitFilename.length - 1].toLowerCase())
        )
    }

    const handleRecordingUpload = async (project: Project, file: File) => {
        setUploadPercent(0)
        const config = {
            onUploadProgress: function (progressEvent: AxiosProgressEvent) {
                setUploadPercent(Math.round((progressEvent.loaded * 100) / (progressEvent.total || 1)))
            },
        } as AxiosRequestConfig<EventTarget>

        if (file) {
            if (isUnsupportedFilename(file.name)) {
                return toast.error(
                    formattedToastMessage(
                        'Unsupported file type',
                        `We don't have support for that file type, make sure the file you want to upload is one of: ${ALLOWED_RECORDING_FILE_NAME_ENDINGS.join(
                            ', '
                        )}.`
                    ),
                    { autoClose: 15_000 }
                )
            }

            try {
                setComponentState(ComponentState.UPLOADING)
                if (props.type === 'recordingSession') {
                    await CloudApi.uploadRecordingSession(project.uid, file, undefined, config)
                } else if (props.type === 'recordingFile' && props.parentRecordingSession) {
                    const filesWithSameName = props.parentRecordingSession.recordings.filter(
                        (it) => it.fileName === file.name
                    )
                    if (filesWithSameName && filesWithSameName.length > 0) {
                        setComponentState(ComponentState.DEFAULT)
                        return toast.warning(`Recording file ${file.name} already exists`)
                    }
                    await CloudApi.uploadRecordingSessionRecordingFile(
                        project.uid,
                        props.parentRecordingSession.sessionId,
                        file,
                        config
                    )
                } else {
                    throw Error(
                        "Upload is neither of type 'recordingSession' or 'recordingFile' with a recording session id"
                    )
                }
                // TODO trigger refresh of parent here!
                if (props.onUploadComplete !== undefined) {
                    props.onUploadComplete()
                }
            } catch (err) {
                console.error(err)
                toast.error(formattedToastMessage('Upload error', 'Failed to upload recording.'))
            }
            setComponentState(ComponentState.DEFAULT)
        }
    }

    const clickableUploadRecordingArea = () => {
        return (
            <div className="d-flex p-1 justify-content-center align-items-center h-100">
                <p className="m-0 remotive-font-md remotive-primary-70-color text-center">
                    <CloudUploadIcon className="me-2" sx={{ fontSize: 35 }} />{' '}
                    {isHoveringDropzone ? (
                        <>
                            Drop the file to <b>upload it!</b>
                        </>
                    ) : (
                        <>
                            Drag a file here or click to <b>upload a recording</b>
                        </>
                    )}
                </p>
            </div>
        )
    }

    const recordingDropzone = (hasPermissionToUpload: boolean, project: Project) => {
        const isUploading = componentState === ComponentState.UPLOADING
        return (
            <>
                {props.helpTextElement}
                <Dropzone
                    ref={refFromParent}
                    disabled={isUploading}
                    multiple={false}
                    onDragEnter={() => {
                        setIssHoveringDropzone(true)
                    }}
                    onDragLeave={() => {
                        setIssHoveringDropzone(false)
                    }}
                    onDrop={(acceptedFiles) => {
                        productAnalyticsClient.track(AnalyticsActions.UPLOAD_RECORDING)
                        setIssHoveringDropzone(false)
                        handleRecordingUpload(project, acceptedFiles[0])
                    }}
                >
                    {({ getRootProps, getInputProps }) => (
                        <div
                            className={`dropzone rounded-2 ${
                                isHoveringDropzone ? 'remotive-primary-10-background' : 'remotive-primary-0-background'
                            }`}
                            style={{
                                height: '94px',
                                ...(props.highlightHelpText !== undefined ? props.highlightHelpText() : {}),
                            }}
                            {...getRootProps()}
                        >
                            <input {...getInputProps()} />
                            <div className="w-100">
                                {isUploading ? (
                                    <InlineFileUploadProgressContainer
                                        inProgressText="Uploading recording..."
                                        finishedText="Upload complete, processing will begin shortly."
                                        currentPercent={uploadPercent}
                                    />
                                ) : (
                                    clickableUploadRecordingArea()
                                )}
                            </div>
                        </div>
                    )}
                </Dropzone>
            </>
        )
    }

    const component = () => {
        if (!props.hasPermissionToUpload) {
            return <></>
        }
        return recordingDropzone(props.hasPermissionToUpload, props.project)
    }

    return component()
})
