import CloudApi from "../../api/CloudApi"
import { AuthenticatedUser, BrokerContainer, Project, RecordingSession, SignalDatabase } from "../../api/CloudApi/types"

export const fetchSignalDatabases = async (project: Project) => {
    return CloudApi.listSignalDatabases(project.uid)
}

export const fetchAndSetSignalDatabases = async (
    project: Project,
    setStateFunction: (signalDatabases: Array<SignalDatabase>) => void
) => {
    const response = await fetchSignalDatabases(project)
    setStateFunction(response.data)
}

export const fetchBrokers = async (project: Project, user: AuthenticatedUser) => {
    return CloudApi.listNonPersonalBrokers(project.uid, user)
}

export const fetchAndSetBrokers = async (
    project: Project,
    user: AuthenticatedUser,
    setStateFunction: (brokers: Array<BrokerContainer>) => void
) => {
    const response = await fetchBrokers(project, user)
    setStateFunction(response)
}

export const fetchRecordingSession = (project: Project, recordingSessionId: string) => {
    return CloudApi.getRecordingSession(project.uid, recordingSessionId)
}

export const fetchAndSetRecordingSession = async (
    project: Project,
    recordingSessionId: string,
    setStateFunction: (reordingSession: RecordingSession) => void
) => {
    const response = await fetchRecordingSession(project, recordingSessionId)
    setStateFunction(response.data)
}