import React from 'react'
import { formatNumberWithMetricPrefix } from '../../../utils/formatNumberWithMetricPrefix';

type SignalDataBarsProps = {
    aggregatedFrameEntries: Array<{ namespace: string; signalDb: string; totalCnt: number }>
}

export default function SignalDataBars(props: SignalDataBarsProps) {
    const maxDataPoints = props.aggregatedFrameEntries.reduce((accumulator, entry) => accumulator + entry.totalCnt, 0)

    const bar = (aggregatedFrameEntry: { namespace: string; signalDb: string; totalCnt: number }) => {
        const barWidth = (aggregatedFrameEntry.totalCnt / (maxDataPoints === 0 ? 1 : maxDataPoints)) * 100

        return (
            <div className=" d-flex justify-content-start align-items-center w-100" style={{ borderRadius: '4px' }}>
                <span
                    className="remotive-primary-50-background"
                    style={{
                        width: `${barWidth}%`,
                        height: '8px',
                        borderRadius: '4px',
                    }}
                ></span>
                <p className="remotive-font-xxs m-0 ms-2 text-secondary">
                    <b>{formatNumberWithMetricPrefix(aggregatedFrameEntry.totalCnt)}</b>
                </p>
            </div>
        )
    }

    return (
        <div className="row mx-1">
            {props.aggregatedFrameEntries
                .sort((a, b) => a.namespace.localeCompare(b.namespace))
                .map((it) => (
                    <div key={`${it.namespace}-${it.signalDb}`} className="col-12 col-xxl-6 p-0">
                        <div className="remotive-primary-10-background rounded-3 p-1 ps-2 mx-1 mb-2">
                            <p className="remotive-font-md m-0">
                                <b>{it.namespace}</b>
                                {` was decoded with `}
                                <b>{it.signalDb}</b>
                            </p>
                            <p className="m-0 remotive-font-sm text-secondary">
                                {' '}
                                {`This namespace contains `}
                                <b>{((it.totalCnt / (maxDataPoints === 0 ? 1 : maxDataPoints)) * 100).toFixed(2)}%</b>
                                {` of the total ${formatNumberWithMetricPrefix(maxDataPoints)} data points`}
                            </p>
                            <>{bar(it)}</>
                        </div>
                    </div>
                ))}
        </div>
    )
}
