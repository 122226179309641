
import { useEffect } from 'react'
import { Modal, ProgressBar, Spinner } from 'react-bootstrap'
import { SuccessIcon } from '../assets/Icons'

interface LoadingOverlayModalProps {
    uploadingText: string
    uploadCompleteText: string
    uploadPercent: number
}

export default function FileUploadingSection(props: LoadingOverlayModalProps) {
    
    const fileUploadingSection = () => {
        return (
            <>
                <div className="text-center h-100 mt-5 mb-5">
                    {props.uploadPercent < 100 && (
                        <div className="mb-3">
                            <ProgressBar
                                className={`col-12 col-lg-8 offset-lg-2 remotive-primary-0-color my-5`}
                                label={`${props.uploadPercent}%`}
                                now={props.uploadPercent}
                            />
                            <p className={`fs-5 m-1 mb-3`}>{props.uploadingText}</p>
                        </div>
                    )}
                    {props.uploadPercent >= 100 && (
                        <div className="mb-3">
                            <SuccessIcon sx={{ fontSize: 40 }} className="my-3 remotive-success-60-color" />
                            <p className={`fs-5 m-1 mb-3`}>{props.uploadCompleteText}</p>
                        </div>
                    )}
                    <Spinner className={`remotive-primary-50-color`} />
                </div>
            </>
        )
    }

    return fileUploadingSection()
}




